
import {
  defineComponent, provide, reactive, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarModeloDocumentoFiscal from '@/components/Compartilhados/SelecionarModeloDocumentoFiscal.vue';
import SelecionarFormaPagamento from '@/components/Cadastros/Financeiro/SelecionarFormaPagamento.vue';
import SelecionarMarcadorNotaFiscal from '@/components/Fiscal/NotasFiscais/SelecionarMarcadorNotaFiscal.vue';
import {
  INotaFiscal, INotaFiscalAnexo, INotaFiscalDetalhePagamento, INotaFiscalDocumentoReferenciadoDfe, INotaFiscalEmitente, INotaFiscalImpostoTotal, INotaFiscalIncidenciaCalculo, INotaFiscalMarcador, INotaFiscalTransporte, INotaFiscalTransporteVolume,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import { ETipoEmissao } from '@/models/Enumeradores/Fiscal/ETipoEmissao';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import SelecionarCfop from '@/components/Cadastros/Tributacoes/Cfops/SelecionarCfop.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import NotaFiscalImpostos from '@/components/Fiscal/NotasFiscais/NotaFiscalImpostos.vue';
import NotaFiscalFrete from '@/components/Fiscal/NotasFiscais/NotaFiscalFrete.vue';
import NotaFiscalDadosAdicionais from '@/components/Fiscal/NotasFiscais/NotaFiscalDadosAdicionais.vue';
import NotaFiscalAnexos from '@/components/Fiscal/NotasFiscais/NotaFiscalAnexos.vue';
import NotaFiscalHistorico from '@/components/Fiscal/NotasFiscais/NotaFiscalHistorico.vue';
import { ETipoFrete } from '@/models/Enumeradores/Compartilhados/ETipoFrete';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { IDTONotaFiscalRegistroAtividade } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscalRegistroAtividade';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { EOrigemNotaFiscal } from '@/models/Enumeradores/Fiscal/EOrigemNotaFiscal';
import { ETipoOperacao } from '@/models/Enumeradores/Fiscal/ETipoOperacao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import SelecionarColaborador from '@/components/Cadastros/Pessoas/Colaboradores/SelecionarColaborador.vue';
import SelecionarCenariofiscal from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCenarioFiscal.vue';
import NotaFiscalDocumentosReferenciados from '@/components/Fiscal/NotasFiscais/NotaFiscalDocumentosReferenciados.vue';
import { ETipoDevolucaoPagamento } from '@/models/Enumeradores/ETipoDevolucaoPagamento';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import storeSistema from '@/store/storeSistema';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import { EOrigemValorItem } from '@/models/Enumeradores/EOrigemValorItem';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import { ETipoMovimentoEstoque } from '@/models/Enumeradores/Estoques/MovimentoEstoque/ETipoMovimentoEstoque';
import ServicoDevolucaoVenda from '@/servicos/Vendas/ServicoDevolucaoVenda';
import { IDevolucaoVenda, IDevolucaoVendaItem } from '@/models/Entidades/Vendas/IDevolucaoVenda';
import { EStatusDevolucaoVenda } from '@/models/Enumeradores/Vendas/EStatusDevolucaoVenda';
import DevolucaoVendaItens from '@/components/Vendas/DevolucoesVendas/DevolucaoVendaItens.vue';
import DevolucaoVendaPagamento from '@/components/Vendas/DevolucoesVendas/DevolucaoVendaPagamento.vue';
import SelecionarCliente from '@/components/Cadastros/Pessoas/Clientes/SelecionarCliente.vue';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';

export default defineComponent({
  name: 'DevolucaoVendaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    Icone,
    SelecionarData,
    SelecionarModeloDocumentoFiscal,
    SelecionarFormaPagamento,
    SelecionarColaborador,
    SelecionarCfop,
    SelecionarCenariofiscal,
    SelecionarMarcadorNotaFiscal,
    DevolucaoVendaItens,
    NotaFiscalImpostos,
    NotaFiscalFrete,
    NotaFiscalDadosAdicionais,
    DevolucaoVendaPagamento,
    NotaFiscalAnexos,
    NotaFiscalHistorico,
    SelecionarCliente,
    NotaFiscalDocumentosReferenciados,
  },
  emits: ['update:operacao', 'update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const servicoDevolucaoVenda = new ServicoDevolucaoVenda();
    const servicoNotaFiscal = new ServicoNotaFiscal();
    const servicoCalculosComerciais = new ServicoCalculosComerciais();
    const servicoEstoque = new ServicoEstoque();
    const servicoSistema = new ServicoSistema();
    let debounceAtualizacaoTotalizadores = 0;
    let debounceSalvamentoAutomatico = 0;
    servicoNotaFiscal.requisicaoSistema();
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis,
      preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    telaBase.identificadorRecurso = 'DEVOLUCOES_VENDAS';
    telaBase.identificadorPermissao = 'PER_DEVOLUCOES_VENDAS';

    const state = reactive({
      devolucaoDb: {} as IDevolucaoVenda,
      devolucao: {} as IDevolucaoVenda,
      detalheCredito: {} as INotaFiscalDetalhePagamento,
      marcadores: [] as string[],
      estoquesDisponiveis: [] as IEstoque[],
      registrosAtividade: [] as IDTONotaFiscalRegistroAtividade[],
      incidenciasCalculo: [] as INotaFiscalIncidenciaCalculo[],
      telaMobile: false,
      totalDetalhesPagamento: 0,
      recuperarSalvamentoAutomatico: false,
      salvamentoAutomatico: {} as ISalvamentoAutomatico,
      editavel: false,
      exibirLancamentoItem: false,
    });

    provide('DV_INCIDENCIA_CALCULO', state.incidenciasCalculo);

    function obtemIdentificadorSalvamentoAutomatico(): string {
      return `${telaBase.identificadorRecurso}_${state.devolucao.codigo}`;
    }

    function validarNumeroNota() {
      if (state.devolucao.notaFiscal.numeroNotaFiscal < 0) {
        state.devolucao.notaFiscal.numeroNotaFiscal = 0;
      }
    }

    function atualizarChaveAcesso() {
      state.devolucao.notaFiscal.chaveAcesso = UtilitarioGeral.extrairNumeros(state.devolucao.notaFiscal.chaveAcesso);
    }

    async function salvamentoAutomatico() {
      if (state.devolucao.codigo > 0) {
        clearTimeout(debounceSalvamentoAutomatico);
        debounceSalvamentoAutomatico = setTimeout(async () => {
          const identificador = obtemIdentificadorSalvamentoAutomatico();
          state.salvamentoAutomatico.data = UtilitarioData.obterDataAtual();
          state.salvamentoAutomatico.codigoEmpresa = state.devolucao.codigoEmpresa;
          state.salvamentoAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
          state.salvamentoAutomatico.identificador = identificador;
          state.salvamentoAutomatico.dados = JSON.stringify(state.devolucao);
          // Salva informações - LocalStorage
          await servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}_${state.salvamentoAutomatico.codigoUsuario}`, state.salvamentoAutomatico);
          // Salva informações - API Servidor
          const retornoSalvamento = await servicoSistema.salvamentoAutomaticoApi(state.salvamentoAutomatico);
          if (retornoSalvamento.status === EStatusRetornoRequisicao.Sucesso) {
            state.salvamentoAutomatico.codigo = retornoSalvamento.codigoRegistro;
          }
          state.recuperarSalvamentoAutomatico = false;
        }, 1000);
      }
    }

    function obterDescricaoStatus(status: EStatusDevolucaoVenda) {
      switch (status) {
        case EStatusDevolucaoVenda.EmDigitacao:
          return 'Em digitação';
        case EStatusDevolucaoVenda.AguardandoEmissao:
          return 'Aguardando Emissão';
        case EStatusDevolucaoVenda.Concluida:
          return 'Concluída';
        default:
          return 'Cancelada';
      }
    }

    function obterCorStatus(status: EStatusDevolucaoVenda) {
      switch (status) {
        case EStatusDevolucaoVenda.EmDigitacao:
          return '#969696';
        case EStatusDevolucaoVenda.AguardandoEmissao:
          return '#d6c720';
        case EStatusDevolucaoVenda.Concluida:
          return '#1843b8';
        default:
          return '#cf2751';
      }
    }

    async function limparTela() {
      state.incidenciasCalculo = servicoDevolucaoVenda.obterIncidenciasNotaFiscalPadrao();
      state.devolucaoDb = {} as IDevolucaoVenda;
      state.devolucao = {} as IDevolucaoVenda;
      state.devolucao.codigo = 0;
      state.devolucao.codigoEmpresa = props.operacao.empresaSelecionada;
      state.devolucao.codigoCenarioFiscal = 0;
      state.devolucao.codigoVendedor = 0;
      state.devolucao.codigoCliente = 0;
      state.devolucao.codigoFormaPagamento = 0;
      state.devolucao.dataCriacao = UtilitarioData.obterDataAtual();
      state.devolucao.tipoDevolucaoPagamento = ETipoDevolucaoPagamento.Manter;
      state.devolucao.status = EStatusDevolucaoVenda.EmDigitacao;
      state.devolucao.itens = [] as IDevolucaoVendaItem[];
      state.devolucao.notaFiscal = {} as INotaFiscal;
      state.devolucao.notaFiscal.codigoEmpresa = state.devolucao.codigoEmpresa;
      state.devolucao.notaFiscal.pessoaIdentificada = true;
      state.devolucao.notaFiscal.origemNotaFiscal = EOrigemNotaFiscal.DevolucaoVendas;
      state.devolucao.notaFiscal.tipoOperacao = ETipoOperacao.Entrada;
      state.devolucao.notaFiscal.tipoFrete = ETipoFrete.SemOcorrenciaTransporte;
      state.devolucao.notaFiscal.tipoEmissao = ETipoEmissao.EmissaoPropria;
      state.devolucao.notaFiscal.modelo = '55';
      state.devolucao.notaFiscal.dataEmissao = UtilitarioData.obterDataAtual();
      state.devolucao.notaFiscal.dataEntradaSaida = UtilitarioData.obterDataAtual();
      state.devolucao.notaFiscal.emitente = {} as INotaFiscalEmitente;
      state.devolucao.notaFiscal.emitente.codigoPessoa = 0;
      state.devolucao.notaFiscal.totalImpostos = {} as INotaFiscalImpostoTotal;
      state.devolucao.notaFiscal.transporte = {} as INotaFiscalTransporte;
      state.devolucao.notaFiscal.transporte.codigoTransportadora = 0;
      state.devolucao.notaFiscal.transporte.volumes = [] as INotaFiscalTransporteVolume[];
      state.devolucao.notaFiscal.totalDesconto = 0;
      state.devolucao.notaFiscal.totalFrete = 0;
      state.devolucao.notaFiscal.totalNotaFiscal = 0;
      state.devolucao.notaFiscal.totalOutrasDespesas = 0;
      state.devolucao.notaFiscal.totalProdutos = 0;
      state.devolucao.notaFiscal.totalSeguro = 0;
      state.devolucao.notaFiscal.totalImpostos.valorAproximadoTributosTotal = 0;
      state.devolucao.notaFiscal.anexos = [] as INotaFiscalAnexo[];
      state.devolucao.notaFiscal.detalhesPagamento = [] as INotaFiscalDetalhePagamento[];
      state.devolucao.notaFiscal.documentosReferenciadosDfe = [] as INotaFiscalDocumentoReferenciadoDfe[];
      state.detalheCredito = {} as INotaFiscalDetalhePagamento;
      state.estoquesDisponiveis = [] as IEstoque[];
      state.marcadores = [] as string[];
      state.registrosAtividade = [] as IDTONotaFiscalRegistroAtividade[];
      state.editavel = false;
      state.exibirLancamentoItem = false;
    }

    async function atualizarValoresTotais() {
      clearTimeout(debounceAtualizacaoTotalizadores);
      debounceAtualizacaoTotalizadores = setTimeout(async () => {
        state.devolucao.notaFiscal.totalProdutos = 0;
        state.devolucao.notaFiscal.totalDesconto = 0;
        state.devolucao.notaFiscal.totalImpostos.baseCalculoIcmsTotal = 0;
        state.devolucao.notaFiscal.totalImpostos.valorIcmsTotal = 0;
        state.devolucao.notaFiscal.totalImpostos.baseCalculoIcmsSubstituicaoTotal = 0;
        state.devolucao.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal = 0;
        state.devolucao.notaFiscal.totalImpostos.baseCalculoIpiTotal = 0;
        state.devolucao.notaFiscal.totalImpostos.valorIpiTotal = 0;
        state.devolucao.notaFiscal.totalImpostos.baseCalculoPisTotal = 0;
        state.devolucao.notaFiscal.totalImpostos.valorPisTotal = 0;
        state.devolucao.notaFiscal.totalImpostos.baseCalculoCofinsTotal = 0;
        state.devolucao.notaFiscal.totalImpostos.valorCofinsTotal = 0;

        for (let index = 0; index < state.devolucao.itens.length; index += 1) {
          if (UtilitarioGeral.validaCodigo(state.devolucao.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
            state.devolucao.itens[index].itemNotaFiscal.valorTotal = servicoCalculosComerciais.calculaValorTotal(state.devolucao.itens[index].itemNotaFiscal.quantidade, state.devolucao.itens[index].itemNotaFiscal.precoUnitario, state.devolucao.itens[index].itemNotaFiscal.valorDesconto, 0);
            if (state.devolucao.itens[index].itemNotaFiscal.compoeTotal) {
              state.devolucao.notaFiscal.totalProdutos += state.devolucao.itens[index].itemNotaFiscal.quantidade * state.devolucao.itens[index].itemNotaFiscal.precoUnitario;
              state.devolucao.notaFiscal.totalDesconto += state.devolucao.itens[index].itemNotaFiscal.valorDesconto;
            }
          }
        }

        state.devolucao.notaFiscal.totalNotaFiscal = state.devolucao.notaFiscal.totalProdutos;

        for (let index = 0; index < state.devolucao.itens.length; index += 1) {
          if (UtilitarioGeral.validaCodigo(state.devolucao.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
            const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.devolucao.itens[index].itemNotaFiscal.quantidade * state.devolucao.itens[index].itemNotaFiscal.precoUnitario));
            if (state.devolucao.itens[index].itemNotaFiscal.origemFrete === EOrigemValorItem.Rateio) {
              if (state.devolucao.notaFiscal.totalFrete > 0) {
                state.devolucao.itens[index].itemNotaFiscal.valorFrete = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.devolucao.notaFiscal.totalProdutos, state.devolucao.notaFiscal.totalFrete);
              } else {
                state.devolucao.itens[index].itemNotaFiscal.valorFrete = 0;
              }
            }

            if (state.devolucao.itens[index].itemNotaFiscal.origemOutrasDespesas === EOrigemValorItem.Rateio) {
              if (state.devolucao.notaFiscal.totalOutrasDespesas > 0) {
                state.devolucao.itens[index].itemNotaFiscal.outrasDespesas = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.devolucao.notaFiscal.totalProdutos, state.devolucao.notaFiscal.totalOutrasDespesas);
              } else {
                state.devolucao.itens[index].itemNotaFiscal.outrasDespesas = 0;
              }
            }

            if (state.devolucao.itens[index].itemNotaFiscal.origemSeguro === EOrigemValorItem.Rateio) {
              if (state.devolucao.notaFiscal.totalSeguro > 0) {
                state.devolucao.itens[index].itemNotaFiscal.valorSeguro = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.devolucao.notaFiscal.totalProdutos, state.devolucao.notaFiscal.totalSeguro);
              } else {
                state.devolucao.itens[index].itemNotaFiscal.valorSeguro = 0;
              }
            }

            const retornoCalculoTributacoes = await servicoCalculosComerciais.calcularTributacoes(state.devolucao.itens[index].itemNotaFiscal, state.incidenciasCalculo);
            if (UtilitarioGeral.objetoValido(retornoCalculoTributacoes)) {
              state.devolucao.itens[index].itemNotaFiscal.impostos.icms.baseCalculoIcms = retornoCalculoTributacoes.icms.baseCalculoIcms;
              state.devolucao.itens[index].itemNotaFiscal.impostos.icms.valorIcms = retornoCalculoTributacoes.icms.valorIcms;

              state.devolucao.notaFiscal.totalImpostos.baseCalculoIcmsTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.icms.baseCalculoIcms;
              state.devolucao.notaFiscal.totalImpostos.valorIcmsTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.icms.valorIcms;

              state.devolucao.itens[index].itemNotaFiscal.impostos.st.baseCalculoSubstituicaoTributaria = retornoCalculoTributacoes.icms.baseCalculoSubstituicaoTributaria;
              state.devolucao.itens[index].itemNotaFiscal.impostos.st.valorSubstituicaoTributaria = retornoCalculoTributacoes.icms.valorSubstituicaoTributaria;

              state.devolucao.notaFiscal.totalImpostos.baseCalculoIcmsSubstituicaoTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.st.baseCalculoSubstituicaoTributaria;
              state.devolucao.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.st.valorSubstituicaoTributaria;

              state.devolucao.itens[index].itemNotaFiscal.impostos.ipi.baseCalculoIpi = retornoCalculoTributacoes.ipi.baseCalculo;
              state.devolucao.itens[index].itemNotaFiscal.impostos.ipi.valorIpi = retornoCalculoTributacoes.ipi.valor;

              state.devolucao.notaFiscal.totalImpostos.baseCalculoIpiTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.ipi.baseCalculoIpi;
              state.devolucao.notaFiscal.totalImpostos.valorIpiTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.ipi.valorIpi;

              state.devolucao.itens[index].itemNotaFiscal.impostos.pis.baseCalculoPis = retornoCalculoTributacoes.pis.baseCalculo;
              state.devolucao.itens[index].itemNotaFiscal.impostos.pis.valorPis = retornoCalculoTributacoes.pis.valor;

              state.devolucao.notaFiscal.totalImpostos.baseCalculoPisTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.pis.baseCalculoPis;
              state.devolucao.notaFiscal.totalImpostos.valorPisTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.pis.valorPis;

              state.devolucao.itens[index].itemNotaFiscal.impostos.cofins.baseCalculoCofins = retornoCalculoTributacoes.cofins.baseCalculo;
              state.devolucao.itens[index].itemNotaFiscal.impostos.cofins.valorCofins = retornoCalculoTributacoes.cofins.valor;

              state.devolucao.notaFiscal.totalImpostos.baseCalculoCofinsTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.cofins.baseCalculoCofins;
              state.devolucao.notaFiscal.totalImpostos.valorCofinsTotal += state.devolucao.itens[index].itemNotaFiscal.impostos.cofins.valorCofins;
            }
          }
        }

        state.devolucao.notaFiscal.totalNotaFiscal = state.devolucao.notaFiscal.totalProdutos;
        const incidenciaTotal = state.incidenciasCalculo.filter((c) => c.incidencia === 'TotalNota');
        incidenciaTotal.forEach((itemIncidencia) => {
          if (itemIncidencia.identificador === 'IPI' && itemIncidencia.valor) {
            state.devolucao.notaFiscal.totalNotaFiscal += state.devolucao.notaFiscal.totalImpostos.valorIpiTotal;
          }
          if (itemIncidencia.identificador === 'Frete' && itemIncidencia.valor) {
            state.devolucao.notaFiscal.totalNotaFiscal += state.devolucao.notaFiscal.totalFrete;
          }
          if (itemIncidencia.identificador === 'Seguro' && itemIncidencia.valor) {
            state.devolucao.notaFiscal.totalNotaFiscal += state.devolucao.notaFiscal.totalSeguro;
          }
          if (itemIncidencia.identificador === 'OutrasDespesas' && itemIncidencia.valor) {
            state.devolucao.notaFiscal.totalNotaFiscal += state.devolucao.notaFiscal.totalOutrasDespesas;
          }
          if (itemIncidencia.identificador === 'Desconto' && itemIncidencia.valor) {
            state.devolucao.notaFiscal.totalNotaFiscal -= state.devolucao.notaFiscal.totalDesconto;
          }
        });
        if (state.devolucao.tipoDevolucaoPagamento === ETipoDevolucaoPagamento.LancarCredito) {
          state.detalheCredito.valorPagamento = state.devolucao.notaFiscal.totalNotaFiscal;
          state.detalheCredito.valorTotal = state.devolucao.notaFiscal.totalNotaFiscal;
        }
        salvamentoAutomatico();
      }, 600);
    }

    function preencherMarcadores() {
      const marcadores = [] as INotaFiscalMarcador[];

      state.marcadores.forEach((m) => {
        const marcador = {} as INotaFiscalMarcador;
        marcador.codigoNotaFiscal = state.devolucao.notaFiscal.codigo;
        marcador.marcador = m;
        marcador.ordem = marcadores.length + 1;
        marcadores.push(marcador);
      });
      return marcadores;
    }

    async function obterMarcadores() {
      state.devolucao.notaFiscal.marcadores = await servicoNotaFiscal.obterMarcadores(state.devolucao.notaFiscal.codigo);
      state.devolucao.notaFiscal.marcadores.forEach((m) => {
        state.marcadores.push(m.marcador);
      });
    }

    async function obterEstoquesDisponiveis() {
      if (state.devolucao.notaFiscal.emitente.codigoPessoa) {
        state.estoquesDisponiveis = await servicoEstoque.obterEstoquesDisponiveis(state.devolucao.codigoEmpresa, state.devolucao.notaFiscal.emitente.codigoPessoa, ETipoMovimentoEstoque.Saida);
      }
      salvamentoAutomatico();
    }

    function atualizarTotalDetalhesPagamento() {
      state.totalDetalhesPagamento = 0;
      state.devolucao.notaFiscal.detalhesPagamento.forEach((d) => {
        state.totalDetalhesPagamento += d.valorTotal;
      });
    }

    async function obterDevolucao(codigo: number) {
      const devolucao = await servicoDevolucaoVenda.obter(codigo, props.operacao.empresaSelecionada);

      devolucao.notaFiscal = await servicoNotaFiscal.obter(devolucao.codigoNotaFiscal);
      if (UtilitarioGeral.validaLista(devolucao.notaFiscal.incidenciasCalculo)) {
        state.incidenciasCalculo = devolucao.notaFiscal.incidenciasCalculo;
      }
      devolucao.notaFiscal.emitente = await servicoNotaFiscal.obterDadosEmitente(devolucao.codigoNotaFiscal);
      const transporte = await servicoNotaFiscal.obterDadosTransporte(devolucao.codigoNotaFiscal);
      if (UtilitarioGeral.objetoValido(transporte)) {
        devolucao.notaFiscal.transporte = transporte;
      } else {
        devolucao.notaFiscal.transporte = {} as INotaFiscalTransporte;
        devolucao.notaFiscal.transporte.volumes = [] as INotaFiscalTransporteVolume[];
      }
      devolucao.notaFiscal.totalImpostos = await servicoNotaFiscal.obterTotaisImpostos(devolucao.codigoNotaFiscal);
      devolucao.notaFiscal.anexos = await servicoNotaFiscal.obterAnexos(devolucao.codigoNotaFiscal);
      devolucao.itens = await servicoDevolucaoVenda.obterItens(devolucao.codigo, devolucao.notaFiscal.codigo, props.operacao.empresaSelecionada);
      devolucao.notaFiscal.detalhesPagamento = await servicoNotaFiscal.obterDetalhesPagamento(devolucao.codigoNotaFiscal);
      if (devolucao.tipoDevolucaoPagamento === ETipoDevolucaoPagamento.LancarCredito) {
        if (devolucao.notaFiscal.detalhesPagamento.length > 0) {
          state.detalheCredito = devolucao.notaFiscal.detalhesPagamento[0];
          devolucao.notaFiscal.detalhesPagamento = [] as INotaFiscalDetalhePagamento[];
        } else {
          state.detalheCredito = {} as INotaFiscalDetalhePagamento;
        }
      }
      devolucao.notaFiscal.documentosReferenciadosDfe = await servicoNotaFiscal.obterDocumentosReferenciados(devolucao.codigoNotaFiscal);
      state.registrosAtividade = await servicoNotaFiscal.obterRegistrosAtividade(devolucao.notaFiscal.codigo);
      state.devolucaoDb = devolucao;
      state.devolucao = UtilitarioGeral.clonarObjeto(state.devolucaoDb);
      obterEstoquesDisponiveis();
      const identificador = obtemIdentificadorSalvamentoAutomatico();
      const salvamentoAutomaticoApi = await servicoSistema.obterUltimoSalvamentoAutomaticoApi(state.devolucao.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), identificador);
      const salvamentoAutomaticoLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(`${identificador}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
      state.salvamentoAutomatico = servicoSistema.obterSalvamentoMaisRecente(salvamentoAutomaticoApi, salvamentoAutomaticoLocalStorage);

      if (UtilitarioData.validaDataPreenchida(state.salvamentoAutomatico.data) && !UtilitarioData.validaDataPreenchida(state.devolucao.dataAlteracao)) {
        state.recuperarSalvamentoAutomatico = true;
      } else if (UtilitarioData.validaDataPreenchida(state.salvamentoAutomatico.data) && UtilitarioData.validaDataPreenchida(state.devolucao.dataAlteracao)) {
        if (UtilitarioData.verificaDataMaisRecente(state.salvamentoAutomatico.data, state.devolucao.dataAlteracao)) { state.recuperarSalvamentoAutomatico = true; }
      }

      atualizarTotalDetalhesPagamento();
      obterMarcadores();
      state.editavel = state.devolucao.status === EStatusDevolucaoVenda.EmDigitacao;
    }

    async function incluirItens() {
      atualizarValoresTotais();
      const itensAdicionados = [] as IDevolucaoVendaItem[];

      state.devolucao.itens.forEach((i, index) => {
        if (state.devolucao.itens[index].codigo === undefined || state.devolucao.itens[index].codigo === 0) {
          state.devolucao.itens[index].codigoDevolucaoVenda = state.devolucao.codigo;
          state.devolucao.itens[index].itemNotaFiscal.codigoNotaFiscal = state.devolucao.notaFiscal.codigo;
          itensAdicionados.push(state.devolucao.itens[index]);
        }
      });
    }

    async function atualizarDetalhePagamento(index: number) {
      if (state.devolucao.codigo === undefined || state.devolucao.codigo === 0) return;
      atualizarTotalDetalhesPagamento();

      if (state.devolucao.notaFiscal.detalhesPagamento[index].codigo === undefined || state.devolucao.notaFiscal.detalhesPagamento[index].codigo === 0) {
        state.devolucao.notaFiscal.detalhesPagamento[index].codigoNotaFiscal = state.devolucao.notaFiscal.codigo;
      }
    }

    async function atualizarAnexo(index: number) {
      if (state.devolucao.codigo === undefined || state.devolucao.codigo === 0) return;

      if (state.devolucao.notaFiscal.anexos[index].codigo === undefined || state.devolucao.notaFiscal.anexos[index].codigo === 0) {
        state.devolucao.notaFiscal.anexos[index].codigoNotaFiscal = state.devolucao.notaFiscal.codigo;
      }
    }

    async function alterarTipoDevolucaoPagamento() {
      if (state.devolucao.tipoDevolucaoPagamento === ETipoDevolucaoPagamento.LancarCredito) {
        state.detalheCredito.valorPagamento = state.devolucao.notaFiscal.totalNotaFiscal;
        state.detalheCredito.valorTotal = state.devolucao.notaFiscal.totalNotaFiscal;
      }
    }

    async function atualizarItem(index: number) {
      atualizarValoresTotais();

      if (state.devolucao.itens[index].codigo === undefined || state.devolucao.itens[index].codigo === 0) {
        state.devolucao.itens[index].codigoDevolucaoVenda = state.devolucao.codigo;
        state.devolucao.itens[index].itemNotaFiscal.codigoNotaFiscal = state.devolucao.notaFiscal.codigo;
      }
    }

    async function excluirItem() {
      if (state.devolucao.codigo === undefined || state.devolucao.codigo === 0) return;
      atualizarValoresTotais();
    }

    async function excluirAnexo(codigoAnexo: number) {
      if (state.devolucao.codigo === undefined || state.devolucao.codigo === 0) return;

      await servicoNotaFiscal.excluirAnexo(codigoAnexo, state.devolucao.codigoEmpresa);
    }

    async function obterCredito() {
      if (state.devolucao.tipoDevolucaoPagamento === ETipoDevolucaoPagamento.LancarCredito) {
        if (state.devolucao.codigo === undefined || state.devolucao.codigo === 0) return;

        if (state.detalheCredito.codigo === undefined || state.detalheCredito.codigo === 0) {
          state.detalheCredito.codigoNotaFiscal = state.devolucao.notaFiscal.codigo;
        }

        state.detalheCredito.data = UtilitarioData.adicionarMesNaData(state.devolucao.dataCriacao, 1);
        state.detalheCredito.valorPagamento = state.devolucao.notaFiscal.totalNotaFiscal;
        state.detalheCredito.valorTotal = state.devolucao.notaFiscal.totalNotaFiscal;
        state.devolucao.notaFiscal.detalhesPagamento = [] as INotaFiscalDetalhePagamento[];
        state.devolucao.notaFiscal.detalhesPagamento.push(state.detalheCredito);
      }
    }

    function validarCampos() {
      if (!UtilitarioGeral.valorValido(state.devolucao.notaFiscal.serie)) {
        apresentarMensagemAlerta('A série deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.devolucao.notaFiscal.dataEmissao)) {
        apresentarMensagemAlerta('A data de emissão deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.devolucao.notaFiscal.dataEntradaSaida)) {
        apresentarMensagemAlerta('A data de saída deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.devolucao.notaFiscal.naturezaOperacao)) {
        apresentarMensagemAlerta('A natureza da operação deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.devolucao.notaFiscal.naturezaOperacao)) {
        apresentarMensagemAlerta('A natureza da operação deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.validaCodigo(state.devolucao.codigoVendedor)) {
        apresentarMensagemAlerta('O vendedor deve ser informado!');
        return false;
      }

      if (!UtilitarioGeral.validaCodigo(state.devolucao.codigoCliente)) {
        apresentarMensagemAlerta('O cliente deve ser informado!');
        return false;
      }

      if (!UtilitarioGeral.validaCodigo(state.devolucao.codigoFormaPagamento)) {
        apresentarMensagemAlerta('A forma de pagamento deve ser informado!');
        return false;
      }

      if (!UtilitarioGeral.validaCodigo(state.devolucao.codigoCenarioFiscal)) {
        apresentarMensagemAlerta('O cenário fiscal deve ser informado!');
        return false;
      }

      if (state.devolucao.notaFiscal.dataEmissao !== state.devolucao.notaFiscal.dataEntradaSaida && UtilitarioData.verificaDataMaisRecente(state.devolucao.notaFiscal.dataEmissao, state.devolucao.notaFiscal.dataEntradaSaida)) {
        apresentarMensagemAlerta('A data de emissão não pode ser posterior a data de entrada!');
        return false;
      }

      return true;
    }

    async function salvar(salvarNovo: boolean) {
      if (!validarCampos()) return;

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso('Aguarde por favor... Estamos salvando a Devolução de Vendas');
      state.devolucao.notaFiscal.codigoEmpresa = state.devolucao.codigoEmpresa;
      state.devolucao.notaFiscal.marcadores = preencherMarcadores();
      state.devolucao.notaFiscal.incidenciasCalculo = state.incidenciasCalculo;
      obterCredito();
      if (state.devolucao.tipoDevolucaoPagamento === ETipoDevolucaoPagamento.AbaterSaldo || state.devolucao.tipoDevolucaoPagamento === ETipoDevolucaoPagamento.Manter) {
        state.devolucao.notaFiscal.detalhesPagamento = [] as INotaFiscalDetalhePagamento[];
      }

      if (state.devolucao.codigo === 0 || state.devolucao.codigo === undefined) {
        retorno = await servicoDevolucaoVenda.incluirCabecalho(state.devolucao);
      } else {
        retorno = await servicoDevolucaoVenda.alterar(state.devolucao);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        apresentarMensagemSucesso(retorno.mensagem);
        const identificador = obtemIdentificadorSalvamentoAutomatico();
        // Remove informações - LocalStorage
        servicoSistema.removerSalvamentoAutomaticoLocalStorage(`${identificador}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
        // Remove informações - API Servidor
        await servicoSistema.removerSalvamentoAutomaticoApi(state.devolucao.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), identificador);

        if (state.devolucao.codigo === 0) {
          await obterDevolucao(retorno.codigoRegistro);
        } else if (salvarNovo) {
          limparTela();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      telaBase.carregando = true;
      limparTela();
      state.editavel = true;
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasDisponiveis();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);

        if (props.operacao.codigoRegistro > 0 && props.operacao.codigoRegistro !== undefined) {
          await obterDevolucao(props.operacao.codigoRegistro);
        }
      }
      telaBase.carregando = false;
    });

    function obtemDescricaoIncidencia(identificador: string): string {
      if (identificador === 'OutrasDespesas') {
        return 'Outras Despesas';
      }

      return identificador;
    }

    function recuperarSalvamentoAutomatico() {
      if (UtilitarioGeral.valorValido(state.salvamentoAutomatico.dados)) {
        const devolucaoSalvaAutomatico = JSON.parse(state.salvamentoAutomatico.dados);
        if (UtilitarioGeral.objetoValido(devolucaoSalvaAutomatico)) {
          state.devolucao = devolucaoSalvaAutomatico;
          state.recuperarSalvamentoAutomatico = false;
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
      }
    }

    function concluirEtapa() {
      Modal.confirm({
        title: `Realmente deseja passar da etapa ${obterDescricaoStatus(state.devolucao.status)} para a etapa ${obterDescricaoStatus(state.devolucao.status + 1)}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoDevolucaoVenda.alterarEtapa(state.devolucao.codigo, state.devolucao.codigoEmpresa, state.devolucao.status + 1);
          if (retorno.status === 1) {
            state.devolucao.status += 1;
            emit('confirmacao');
          } else if (retorno.status === 2) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function retornarEtapa() {
      Modal.confirm({
        title: `Realmente deseja passar da etapa ${obterDescricaoStatus(state.devolucao.status)} para a etapa ${obterDescricaoStatus(state.devolucao.status - 1)}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoDevolucaoVenda.alterarEtapa(state.devolucao.codigo, state.devolucao.codigoEmpresa, state.devolucao.status - 1);
          if (retorno.status === 1) {
            state.devolucao.status -= 1;
            emit('confirmacao');
          } else if (retorno.status === 2) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function cancelar() {
      Modal.confirm({
        title: 'Realmente deseja cancelar a devolução?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoDevolucaoVenda.alterarEtapa(state.devolucao.codigo, state.devolucao.codigoEmpresa, EStatusDevolucaoVenda.Cancelada);
          if (retorno.status === 1) {
            state.devolucao.status = EStatusDevolucaoVenda.Cancelada;
            emit('confirmacao');
          } else if (retorno.status === 2) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function excluir() {
      Modal.confirm({
        title: 'Realmente deseja excluir a devolução?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoDevolucaoVenda.excluirVarios(state.devolucao.codigoEmpresa, [state.devolucao.codigo]);
          if (retorno.status !== 1) {
            apresentarMensagemErro(retorno.mensagem);
          } else {
            emit('confirmacao');
            modalBase.computedVisivel = false;
          }
        },
      });
    }

    async function imprimir() {
      const retornoRelatorio = await servicoDevolucaoVenda.imprimirDevolucao(state.devolucao.codigo, state.devolucao.codigoEmpresa, ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        window.open(retornoRelatorio.link, '_blank');
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      ETipoEmissao,
      ETipoMovimentoComercial,
      UtilitarioGeral,
      UtilitarioMascara,
      apresentarRetornoRequisicao,
      obterDescricaoStatus,
      obterCorStatus,
      atualizarValoresTotais,
      atualizarChaveAcesso,
      incluirItens,
      atualizarItem,
      atualizarDetalhePagamento,
      excluirItem,
      atualizarAnexo,
      excluirAnexo,
      salvar,
      alterarTipoDevolucaoPagamento,
      EStatusDevolucaoVenda,
      concluirEtapa,
      retornarEtapa,
      cancelar,
      excluir,
      salvamentoAutomatico,
      imprimir,
      recuperarSalvamentoAutomatico,
      obtemDescricaoIncidencia,
      obterEstoquesDisponiveis,
      validarNumeroNota,
    };
  },
});
