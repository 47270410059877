
import {
  defineComponent, inject, reactive, watch, Ref, provide,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useRequisicaoAutorizacaoBase } from '@/composables/MeuSistema/RequisicaoAutorizacaoBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarFormaPagamento from '@/components/Cadastros/Financeiro/SelecionarFormaPagamento.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { ETipoFrete } from '@/models/Enumeradores/Compartilhados/ETipoFrete';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import SelecionarColaborador from '@/components/Cadastros/Pessoas/Colaboradores/SelecionarColaborador.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IEtapaProcessoVendas, IProcessoVendas } from '@/models/Entidades/Cadastros/Vendas/IProcessoVendas';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import SelecionarMarcadorNotaFiscal from '@/components/Fiscal/NotasFiscais/SelecionarMarcadorNotaFiscal.vue';
import VendaItens from '@/components/Vendas/Vendas/ModalVenda/VendaItens.vue';
import SelecionarStatusVenda from '@/components/Vendas/SelecionarStatusVenda.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import { IVenda, IVendaItem } from '@/models/Entidades/Vendas/IVenda';
import {
  INotaFiscal, INotaFiscalAnexo, INotaFiscalDestinatario, INotaFiscalDetalhePagamento, INotaFiscalEmitente, INotaFiscalImpostoTotal, INotaFiscalIncidenciaCalculo, INotaFiscalMarcador, INotaFiscalTransporte, INotaFiscalTransporteVolume,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { ETipoValorMovimentacao } from '@/models/Enumeradores/ETipoValorMovimentacao';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import { EOrigemValorItem } from '@/models/Enumeradores/EOrigemValorItem';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import ServicoFormaPagamento from '@/servicos/Cadastros/Financeiro/ServicoFormaPagamento';
import { IFormaPagamento } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import RequisitarAutorizacaoModal from '@/components/MeuSistema/ControleAutorizacoes/RequisitarAutorizacaoModal.vue';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import NotaFiscalImpostos from '@/components/Fiscal/NotasFiscais/NotaFiscalImpostos.vue';
import NotaFiscalFrete from '@/components/Fiscal/NotasFiscais/NotaFiscalFrete.vue';
import NotaFiscalAnexos from '@/components/Fiscal/NotasFiscais/NotaFiscalAnexos.vue';
import NotaFiscalHistorico from '@/components/Fiscal/NotasFiscais/NotaFiscalHistorico.vue';
import { ETipoOperacao } from '@/models/Enumeradores/Fiscal/ETipoOperacao';
import { ETipoEmissao } from '@/models/Enumeradores/Fiscal/ETipoEmissao';
import { IDTONotaFiscalRegistroAtividade } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscalRegistroAtividade';
import { useFocusBase } from '@/core/composables/FocusBase';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { IDTOPessoaMovimento } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaMovimento';
import VendaDetalhesPagamento from '@/components/Vendas/Vendas/ModalVenda/PagamentoVenda/VendaDetalhesPagamento.vue';
import ServicoCfop from '@/servicos/Cadastros/Tributacoes/ServicoCfop';
import { ICfop } from '@/models/Entidades/Cadastros/Tributacoes/ICfop';
import BarraProgresso from '@/core/components/Tela/BarraProgresso.vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';

export default defineComponent({
  name: 'VendaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    codigoVenda: {
      type: Number,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    PreviewPdf,
    Icone,
    BarraProgresso,
    EditorHtml,
    SelecionarData,
    SelecionarColaborador,
    SelecionarPessoa,
    SelecionarFormaPagamento,
    SelecionarMarcadorNotaFiscal,
    VendaItens,
    SelecionarStatusVenda,
    RequisitarAutorizacaoModal,
    NotaFiscalImpostos,
    NotaFiscalFrete,
    VendaDetalhesPagamento,
    NotaFiscalAnexos,
    NotaFiscalHistorico,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const servicoVendas = new ServicoVendas();
    const servicoCalculosComerciais = new ServicoCalculosComerciais();
    const servicoFormaPagamento = new ServicoFormaPagamento();
    servicoFormaPagamento.requisicaoSistema();
    const servicoNotaFiscal = new ServicoNotaFiscal();
    servicoNotaFiscal.requisicaoSistema();
    const servicoSistema = new ServicoSistema();
    const servicoPessoa = new ServicoPessoa();
    servicoPessoa.requisicaoSistema();

    const servicoCfop = new ServicoCfop();
    servicoCfop.requisicaoSistema();

    const {
      telaBase,
      apresentarMensagemSucesso, apresentarMensagemAlerta, preencherEmpresasDisponiveis, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemErro,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const {
      focusBase, focusElemento, proximoElemento, elementoAnterior,
    } = useFocusBase();

    focusBase.classElementos = 'ss-venda-dados-principais';

    const { requisicaoAutorizacaoBase } = useRequisicaoAutorizacaoBase();
    const dadosEmpresa = inject('VENDAS_EMPRESA') as Ref<IDTODadosEmpresa>;
    const processoVendas = inject('VENDAS_PROCESSO_VENDAS') as Ref<IProcessoVendas>;
    const etapa = inject('VENDAS_ETAPA_LANCAMENTO') as Ref<IEtapaProcessoVendas>;

    telaBase.identificadorRecurso = 'VENDAS';
    let debounceCacheRegistro = 0;
    let debounceAtualizacaoTotalizadores = 0;
    let debounceRenderizar = 0;
    const state = reactive({
      venda: {} as IVenda,
      dadosPessoaMovimento: {} as IDTOPessoaMovimento,
      marcadores: [] as string[],
      registrosAtividade: [] as IDTONotaFiscalRegistroAtividade[],
      incidenciasCalculo: [] as INotaFiscalIncidenciaCalculo[],
      cacheRegistro: {} as ISalvamentoAutomatico,
      tabelasPrecosDisponiveis: [] as number[],
      totalCaixaLancado: 0,
      totalCarteiraLancado: 0,
      totalOutrosLancado: 0,
      telaMobile: false,
      editavel: false,
      cacheDisponivel: false,
      calculandoValores: false,
      chaveItens: 0,
      exibirAlteracaoEtapa: false,
      carregando: false,
      carregandoItens: false,
      adicionarPrimeiroItem: false,
      buscarDadosFiscaisRecalcular: false,
      criacaoVendaAutomatico: false,
      cfopSugerido: {} as ICfop,
      incluindoCabecalhoVenda: false,
      arquivosPdf: [] as IArquivoPdf[],
    });

    provide('VENDA_INCIDENCIA_CALCULO', state.incidenciasCalculo);
    provide('VENDAS_CFOP_SUGERIDO', state.cfopSugerido);

    async function limparTela() {
      state.criacaoVendaAutomatico = false;
      state.incluindoCabecalhoVenda = false;
      state.adicionarPrimeiroItem = false;
      state.buscarDadosFiscaisRecalcular = false;
      state.incidenciasCalculo = servicoVendas.obterIncidenciasNotaFiscalVendaPadrao();
      state.totalCaixaLancado = 0;
      state.totalCarteiraLancado = 0;
      state.totalOutrosLancado = 0;
      state.venda = {} as IVenda;
      state.venda.codigo = 0;
      state.venda.codigoEmpresa = dadosEmpresa.value.codigoEmpresa;
      state.venda.codigoProcessoVendas = etapa.value.codigoProcessoVendas;
      state.venda.codigoEtapaProcessoVendas = etapa.value.codigo;
      state.venda.dataVenda = UtilitarioData.obterDataAtual();
      state.venda.codigoFormaPagamento = 0;
      state.venda.codigoVendedor = 0;
      state.venda.codigoCenarioFiscal = 1;
      state.venda.codigoCliente = 0;
      state.venda.codigoContatoCliente = 0;
      state.venda.dataCriacao = UtilitarioData.obterDataAtual();
      state.venda.itens = [] as IVendaItem[];
      state.venda.notaFiscal = {} as INotaFiscal;
      state.venda.notaFiscal.numeroNotaFiscal = 0;
      state.venda.notaFiscal.naturezaOperacao = 'NATUREZA';
      state.venda.notaFiscal.codigoEmpresa = state.venda.codigoEmpresa;
      state.venda.notaFiscal.destinatario = {} as INotaFiscalDestinatario;
      state.venda.notaFiscal.destinatario.codigoPessoa = 0;
      state.venda.notaFiscal.pessoaIdentificada = true;

      state.venda.notaFiscal.tipoOperacao = ETipoOperacao.Saida;
      state.venda.notaFiscal.tipoFrete = ETipoFrete.SemOcorrenciaTransporte;
      state.venda.notaFiscal.tipoEmissao = ETipoEmissao.EmissaoPropria;
      state.venda.notaFiscal.modelo = '55';
      state.venda.notaFiscal.dataEmissao = UtilitarioData.obterDataAtual();
      state.venda.notaFiscal.dataEntradaSaida = UtilitarioData.obterDataAtual();
      state.venda.notaFiscal.emitente = {} as INotaFiscalEmitente;
      state.venda.notaFiscal.emitente.codigoPessoa = 0;
      state.venda.notaFiscal.totalImpostos = {} as INotaFiscalImpostoTotal;
      state.venda.notaFiscal.transporte = {} as INotaFiscalTransporte;
      state.venda.notaFiscal.transporte.codigoTransportadora = 0;
      state.venda.notaFiscal.transporte.volumes = [] as INotaFiscalTransporteVolume[];
      state.venda.notaFiscal.totalDesconto = 0;
      state.venda.notaFiscal.totalFrete = 0;
      state.venda.notaFiscal.totalNotaFiscal = 0;
      state.venda.notaFiscal.totalOutrasDespesas = 0;
      state.venda.notaFiscal.totalProdutos = 0;
      state.venda.notaFiscal.totalSeguro = 0;
      state.venda.notaFiscal.totalImpostos.valorAproximadoTributosTotal = 0;
      state.venda.notaFiscal.anexos = [] as INotaFiscalAnexo[];
      state.venda.notaFiscal.detalhesPagamento = [] as INotaFiscalDetalhePagamento[];
      state.venda.formaPagamento = {} as IFormaPagamento;
      state.venda.atualizarValores = false;
      state.marcadores = [] as string[];
      state.registrosAtividade = [] as IDTONotaFiscalRegistroAtividade[];
      state.venda.status = etapa.value.statusVenda;
      state.editavel = props.editavel;
      state.cacheDisponivel = false;
      state.cacheRegistro = {} as ISalvamentoAutomatico;
      state.cacheRegistro.codigo = 0;
      state.exibirAlteracaoEtapa = false;
      state.carregando = false;
      state.carregandoItens = false;
    }

    function obterIdentificadorCache() {
      return `${telaBase.identificadorRecurso}_${etapa.value.codigoProcessoVendas}_${etapa.value.codigo}_${state.venda.codigo}`;
    }

    async function salvarCacheRegistro() {
      clearTimeout(debounceCacheRegistro);
      debounceCacheRegistro = setTimeout(async () => {
        state.cacheRegistro.data = UtilitarioData.obterDataAtual();
        state.cacheRegistro.codigoEmpresa = state.venda.codigoEmpresa;
        state.cacheRegistro.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
        state.cacheRegistro.identificador = obterIdentificadorCache();
        state.cacheRegistro.dados = JSON.stringify(state.venda);
        await servicoSistema.salvamentoAutomaticoLocalStorage(`${obterIdentificadorCache()}_${state.cacheRegistro.codigoUsuario}`, state.cacheRegistro);
        const retornoSalvamento = await servicoSistema.salvamentoAutomaticoApi(state.cacheRegistro);
        if (retornoSalvamento.status === EStatusRetornoRequisicao.Sucesso) {
          state.cacheRegistro.codigo = retornoSalvamento.codigoRegistro;
        }
      }, 600);
    }

    async function atualizarValoresTotais() {
      if (!telaBase.carregando && !state.carregandoItens) {
        clearTimeout(debounceAtualizacaoTotalizadores);
        debounceAtualizacaoTotalizadores = setTimeout(async () => {
          state.venda.notaFiscal.totalProdutos = 0;
          state.venda.notaFiscal.totalDesconto = 0;
          state.venda.notaFiscal.totalImpostos.baseCalculoIcmsTotal = 0;
          state.venda.notaFiscal.totalImpostos.valorIcmsTotal = 0;
          state.venda.notaFiscal.totalImpostos.baseCalculoIcmsSubstituicaoTotal = 0;
          state.venda.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal = 0;
          state.venda.notaFiscal.totalImpostos.baseCalculoIpiTotal = 0;
          state.venda.notaFiscal.totalImpostos.valorIpiTotal = 0;
          state.venda.notaFiscal.totalImpostos.baseCalculoPisTotal = 0;
          state.venda.notaFiscal.totalImpostos.valorPisTotal = 0;
          state.venda.notaFiscal.totalImpostos.baseCalculoCofinsTotal = 0;
          state.venda.notaFiscal.totalImpostos.valorCofinsTotal = 0;
          state.venda.notaFiscal.totalImpostos.valorAproximadoTributosTotal = 0;

          for (let index = 0; index < state.venda.itens.length; index += 1) {
            if (UtilitarioGeral.validaCodigo(state.venda.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
              state.venda.itens[index].itemNotaFiscal.valorTotal = servicoCalculosComerciais.calculaValorTotal(state.venda.itens[index].itemNotaFiscal.quantidade, state.venda.itens[index].itemNotaFiscal.precoUnitario, state.venda.itens[index].itemNotaFiscal.valorDesconto, 0);
              if (state.venda.itens[index].itemNotaFiscal.compoeTotal) {
                state.venda.notaFiscal.totalProdutos += state.venda.itens[index].itemNotaFiscal.quantidade * state.venda.itens[index].itemNotaFiscal.precoUnitario;
                state.venda.notaFiscal.totalDesconto += state.venda.itens[index].itemNotaFiscal.valorDesconto;
              }
            }
          }

          state.venda.notaFiscal.totalNotaFiscal = state.venda.notaFiscal.totalProdutos;
          for (let index = 0; index < state.venda.itens.length; index += 1) {
            if (UtilitarioGeral.validaCodigo(state.venda.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
              const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.venda.itens[index].itemNotaFiscal.quantidade * state.venda.itens[index].itemNotaFiscal.precoUnitario));

              if (state.venda.itens[index].itemNotaFiscal.origemFrete === EOrigemValorItem.Rateio) {
                if (state.venda.notaFiscal.totalFrete > 0) {
                  state.venda.itens[index].itemNotaFiscal.valorFrete = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.venda.notaFiscal.totalProdutos, state.venda.notaFiscal.totalFrete);
                } else {
                  state.venda.itens[index].itemNotaFiscal.valorFrete = 0;
                }
              }

              if (state.venda.itens[index].itemNotaFiscal.origemOutrasDespesas === EOrigemValorItem.Rateio) {
                if (state.venda.notaFiscal.totalOutrasDespesas > 0) {
                  state.venda.itens[index].itemNotaFiscal.outrasDespesas = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.venda.notaFiscal.totalProdutos, state.venda.notaFiscal.totalOutrasDespesas);
                } else {
                  state.venda.itens[index].itemNotaFiscal.outrasDespesas = 0;
                }
              }

              if (state.venda.itens[index].itemNotaFiscal.origemSeguro === EOrigemValorItem.Rateio) {
                if (state.venda.notaFiscal.totalSeguro > 0) {
                  state.venda.itens[index].itemNotaFiscal.valorSeguro = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.venda.notaFiscal.totalProdutos, state.venda.notaFiscal.totalSeguro);
                } else {
                  state.venda.itens[index].itemNotaFiscal.valorSeguro = 0;
                }
              }
              state.venda.itens[index].itemNotaFiscal.impostos.icms.aliquotaSimplesNacional = dadosEmpresa.value.aliquotaSimplesNacional;
              const retornoCalculoTributacoes = await servicoCalculosComerciais.calcularTributacoes(state.venda.itens[index].itemNotaFiscal, state.incidenciasCalculo, dadosEmpresa.value.uf);
              if (UtilitarioGeral.objetoValido(retornoCalculoTributacoes)) {
                state.venda.itens[index].itemNotaFiscal.impostos.icms.baseCalculoIcms = retornoCalculoTributacoes.icms.baseCalculoIcms;
                state.venda.itens[index].itemNotaFiscal.impostos.icms.valorIcms = retornoCalculoTributacoes.icms.valorIcms;
                state.venda.itens[index].itemNotaFiscal.impostos.icms.valorSimplesNacional = retornoCalculoTributacoes.icms.valorSimplesNacional;

                state.venda.notaFiscal.totalImpostos.baseCalculoIcmsTotal += state.venda.itens[index].itemNotaFiscal.impostos.icms.baseCalculoIcms;
                state.venda.notaFiscal.totalImpostos.valorIcmsTotal += state.venda.itens[index].itemNotaFiscal.impostos.icms.valorIcms;

                state.venda.itens[index].itemNotaFiscal.impostos.st.baseCalculoSubstituicaoTributaria = retornoCalculoTributacoes.icms.baseCalculoSubstituicaoTributaria;
                state.venda.itens[index].itemNotaFiscal.impostos.st.valorSubstituicaoTributaria = retornoCalculoTributacoes.icms.valorSubstituicaoTributaria;

                state.venda.notaFiscal.totalImpostos.baseCalculoIcmsSubstituicaoTotal += state.venda.itens[index].itemNotaFiscal.impostos.st.baseCalculoSubstituicaoTributaria;
                state.venda.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal += state.venda.itens[index].itemNotaFiscal.impostos.st.valorSubstituicaoTributaria;

                state.venda.itens[index].itemNotaFiscal.impostos.ipi.baseCalculoIpi = retornoCalculoTributacoes.ipi.baseCalculo;
                state.venda.itens[index].itemNotaFiscal.impostos.ipi.valorIpi = retornoCalculoTributacoes.ipi.valor;

                state.venda.notaFiscal.totalImpostos.baseCalculoIpiTotal += state.venda.itens[index].itemNotaFiscal.impostos.ipi.baseCalculoIpi;
                state.venda.notaFiscal.totalImpostos.valorIpiTotal += state.venda.itens[index].itemNotaFiscal.impostos.ipi.valorIpi;

                state.venda.itens[index].itemNotaFiscal.impostos.pis.baseCalculoPis = retornoCalculoTributacoes.pis.baseCalculo;
                state.venda.itens[index].itemNotaFiscal.impostos.pis.valorPis = retornoCalculoTributacoes.pis.valor;

                state.venda.notaFiscal.totalImpostos.baseCalculoPisTotal += state.venda.itens[index].itemNotaFiscal.impostos.pis.baseCalculoPis;
                state.venda.notaFiscal.totalImpostos.valorPisTotal += state.venda.itens[index].itemNotaFiscal.impostos.pis.valorPis;

                state.venda.itens[index].itemNotaFiscal.impostos.cofins.baseCalculoCofins = retornoCalculoTributacoes.cofins.baseCalculo;
                state.venda.itens[index].itemNotaFiscal.impostos.cofins.valorCofins = retornoCalculoTributacoes.cofins.valor;

                state.venda.notaFiscal.totalImpostos.baseCalculoCofinsTotal += state.venda.itens[index].itemNotaFiscal.impostos.cofins.baseCalculoCofins;
                state.venda.notaFiscal.totalImpostos.valorCofinsTotal += state.venda.itens[index].itemNotaFiscal.impostos.cofins.valorCofins;

                state.venda.itens[index].itemNotaFiscal.impostos.valorTotalTributos = retornoCalculoTributacoes.valorTotalTributos;
                state.venda.notaFiscal.totalImpostos.valorAproximadoTributosTotal += state.venda.itens[index].itemNotaFiscal.impostos.valorTotalTributos;
              }
            }
          }

          state.venda.notaFiscal.totalNotaFiscal = state.venda.notaFiscal.totalProdutos;
          const incidenciaTotal = state.incidenciasCalculo.filter((c) => c.incidencia === 'TotalNota');
          incidenciaTotal.forEach((itemIncidencia) => {
            if (itemIncidencia.identificador === 'IPI' && itemIncidencia.valor) {
              state.venda.notaFiscal.totalNotaFiscal += state.venda.notaFiscal.totalImpostos.valorIpiTotal;
            }
            if (itemIncidencia.identificador === 'Frete' && itemIncidencia.valor) {
              state.venda.notaFiscal.totalNotaFiscal += state.venda.notaFiscal.totalFrete;
            }
            if (itemIncidencia.identificador === 'Seguro' && itemIncidencia.valor) {
              state.venda.notaFiscal.totalNotaFiscal += state.venda.notaFiscal.totalSeguro;
            }
            if (itemIncidencia.identificador === 'OutrasDespesas' && itemIncidencia.valor) {
              state.venda.notaFiscal.totalNotaFiscal += state.venda.notaFiscal.totalOutrasDespesas;
            }
            if (itemIncidencia.identificador === 'Desconto' && itemIncidencia.valor) {
              state.venda.notaFiscal.totalNotaFiscal -= state.venda.notaFiscal.totalDesconto;
            }
          });

          salvarCacheRegistro();
        }, 600);
      }
    }

    function adicionarTaxasPagamento(acrescimo: number, outrasTaxas: number) {
      state.venda.notaFiscal.totalOutrasDespesas += outrasTaxas;
      if (acrescimo > 0) {
        state.venda.itens.forEach((i, index) => {
          state.venda.itens[index].itemNotaFiscal.precoUnitario = state.venda.itens[index].precoTabela;
          const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.venda.itens[index].itemNotaFiscal.quantidade * state.venda.itens[index].itemNotaFiscal.precoUnitario));
          const acrescimoItem = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.venda.notaFiscal.totalProdutos, acrescimo);
          state.venda.itens[index].itemNotaFiscal.precoUnitario += (acrescimoItem / state.venda.itens[index].itemNotaFiscal.quantidade);
        });
        atualizarValoresTotais();
      }
    }

    function removerTaxasPagamento(acrescimo: number, outrasTaxas: number) {
      state.venda.notaFiscal.totalOutrasDespesas -= outrasTaxas;
      state.venda.itens.forEach((i, index) => {
        const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.venda.itens[index].itemNotaFiscal.quantidade * state.venda.itens[index].itemNotaFiscal.precoUnitario));
        if (acrescimo > 0) {
          const acrescimoItem = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.venda.notaFiscal.totalProdutos, acrescimo);
          state.venda.itens[index].itemNotaFiscal.precoUnitario -= (acrescimoItem / state.venda.itens[index].itemNotaFiscal.quantidade);
        }
      });
      atualizarValoresTotais();
    }

    async function atualizarInformacoesPessoa() {
      state.dadosPessoaMovimento = await servicoPessoa.obterDadosMovimentacao(state.venda.notaFiscal.destinatario.codigoPessoa, state.venda.codigoEmpresa);
      let cfop = 0;

      if (state.dadosPessoaMovimento.uf === dadosEmpresa.value.uf) {
        cfop = 5102;
      } else if (state.dadosPessoaMovimento.uf === 'EX') {
        cfop = 7102;
      } else {
        cfop = 6102;
      }

      state.cfopSugerido = await servicoCfop.obterPeloCodigoCfop(cfop);

      state.buscarDadosFiscaisRecalcular = true;
      state.criacaoVendaAutomatico = servicoVendas.estrategiaCriacaoVendaAutomatico(state.venda);
    }

    async function atualizarInformacoesColaborador() {
      await salvarCacheRegistro();
      state.criacaoVendaAutomatico = servicoVendas.estrategiaCriacaoVendaAutomatico(state.venda);
    }

    async function recuperarCacheRegistro() {
      if (UtilitarioGeral.valorValido(state.cacheRegistro.dados)) {
        telaBase.carregando = true;
        const vendaCache = JSON.parse(state.cacheRegistro.dados);
        if (UtilitarioGeral.objetoValido(vendaCache)) {
          state.venda = vendaCache;
          state.cacheDisponivel = false;
          state.cacheRegistro = {} as ISalvamentoAutomatico;
          state.cacheRegistro.codigo = 0;
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
        telaBase.carregando = false;
      }
    }

    async function excluirCacheRegistro() {
      servicoSistema.removerSalvamentoAutomaticoApi(dadosEmpresa.value.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), obterIdentificadorCache());
      servicoSistema.removerSalvamentoAutomaticoLocalStorage(`${obterIdentificadorCache()}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
    }

    async function obterCacheRegistro() {
      let dataAlteracao = UtilitarioData.obterDataAtual();
      if (props.codigoVenda > 0 && props.codigoVenda !== undefined) {
        dataAlteracao = state.venda.dataAlteracao;
      }

      const cacheApi = await servicoSistema.obterUltimoSalvamentoAutomaticoApi(dadosEmpresa.value.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), obterIdentificadorCache());
      const cacheLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(`${obterIdentificadorCache()}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
      state.cacheRegistro = servicoSistema.obterSalvamentoMaisRecente(cacheApi, cacheLocalStorage);

      if (UtilitarioData.validaDataPreenchida(state.cacheRegistro.data) && (props.codigoVenda === 0 || props.codigoVenda === undefined)) {
        state.cacheDisponivel = true;
        excluirCacheRegistro();
      } else if (UtilitarioData.validaDataPreenchida(state.cacheRegistro.data) && UtilitarioData.verificaDataMaisRecente(state.cacheRegistro.data, dataAlteracao)) {
        const vendaCache = JSON.parse(state.cacheRegistro.dados);
        if (vendaCache !== state.venda) {
          state.cacheDisponivel = true;
          excluirCacheRegistro();
        }
      }
    }

    async function obterFormaPagamento() {
      if (UtilitarioGeral.validaCodigo(state.venda.codigoFormaPagamento)) {
        state.venda.formaPagamento = await servicoFormaPagamento.obter(state.venda.codigoFormaPagamento);
        state.venda.formaPagamento.tiposDocumentosFinanceiro = await servicoFormaPagamento.obterTiposDocumentos(state.venda.codigoFormaPagamento, state.venda.codigoEmpresa);
        state.venda.formaPagamento.tabelasPreco = await servicoFormaPagamento.obterTabelasPreco(state.venda.codigoFormaPagamento, state.venda.codigoEmpresa);
        state.venda.formaPagamento.tabelasPreco.forEach((t) => {
          state.tabelasPrecosDisponiveis.push(t.codigoTabelaPreco);
        });
        salvarCacheRegistro();
      } else {
        state.venda.formaPagamento = {} as IFormaPagamento;
      }

      state.criacaoVendaAutomatico = servicoVendas.estrategiaCriacaoVendaAutomatico(state.venda);
    }

    async function obterMarcadores() {
      state.venda.notaFiscal.marcadores.forEach((m) => {
        state.marcadores.push(m.marcador);
      });
    }

    function preencherMarcadores() {
      const marcadores = [] as INotaFiscalMarcador[];

      state.marcadores.forEach((m) => {
        const marcador = {} as INotaFiscalMarcador;
        marcador.codigoNotaFiscal = state.venda.notaFiscal.codigo;
        marcador.marcador = m;
        marcador.ordem = marcadores.length + 1;
        marcadores.push(marcador);
      });
      return marcadores;
    }

    async function buscarItens() {
      const itens = await servicoVendas.obterItens(state.venda.codigo);
      state.venda.itens = itens;
    }

    async function buscarItensDadosAtualizados(atualizarValores: boolean) {
      state.venda.atualizarValores = atualizarValores;
      await buscarItens();
    }

    async function obterItens() {
      state.carregandoItens = true;
      await buscarItens();
      state.carregandoItens = false;
    }

    async function obterVenda(codigo: number, buscarItens = true) {
      state.criacaoVendaAutomatico = false;
      telaBase.carregando = true;

      state.venda = await servicoVendas.obter(codigo);
      state.totalCaixaLancado = 0;
      state.totalCarteiraLancado = 0;
      state.totalOutrosLancado = 0;
      state.venda.notaFiscal.detalhesPagamento.forEach((d) => {
        if (d.gerarCaixa) {
          state.totalCaixaLancado += d.valorTotal;
        } else if (d.gerarCarteira) {
          state.totalCarteiraLancado += d.valorTotal;
        } else {
          state.totalOutrosLancado += d.valorTotal;
        }
      });
      await atualizarInformacoesPessoa();
      state.venda.atualizarValores = false;
      state.venda.itens = [] as IVendaItem[];
      if (UtilitarioGeral.validaLista(state.venda.notaFiscal.incidenciasCalculo)) {
        state.incidenciasCalculo = state.venda.notaFiscal.incidenciasCalculo;
      }
      telaBase.carregando = false;
      obterMarcadores();
      obterFormaPagamento();
      if (buscarItens) {
        await obterItens();
      }

      state.registrosAtividade = await servicoNotaFiscal.obterRegistrosAtividade(state.venda.notaFiscal.codigo);
      state.editavel = props.editavel;
      if (state.editavel && !UtilitarioGeral.validaLista(state.venda.itens)) {
        state.adicionarPrimeiroItem = true;
      }
    }

    function validarCampos() {
      if (!UtilitarioGeral.validaCodigo(state.venda.codigoVendedor)) {
        apresentarMensagemAlerta('O vendedor deve ser informado!');
        return false;
      }

      if (!UtilitarioGeral.validaCodigo(state.venda.codigoCliente)) {
        apresentarMensagemAlerta('O cliente deve ser informado!');
        return false;
      }

      if (!UtilitarioGeral.validaCodigo(state.venda.codigoFormaPagamento)) {
        apresentarMensagemAlerta('A forma de pagamento deve ser informado!');
        return false;
      }

      return true;
    }

    async function salvar() {
      if (!validarCampos()) return;

      state.criacaoVendaAutomatico = false;

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      state.venda.notaFiscal.detalhesPagamento.forEach((d) => {
        if (!UtilitarioGeral.validaCodigo(d.codigoPlanoContaCategoria) && retorno.status !== EStatusRetornoRequisicao.Erro) {
          retorno.status = EStatusRetornoRequisicao.Erro;
          apresentarMensagemAlerta('Todos os detalhes de pagamento devem ter categoria definida!');
        }
      });
      if (retorno.status === EStatusRetornoRequisicao.Erro) {
        return;
      }

      const retornoValidacaoPagamento = await servicoVendas.validarFormaPagamento(state.venda.notaFiscal, state.venda.formaPagamento);
      if (retornoValidacaoPagamento.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarRetornoRequisicao(retornoValidacaoPagamento);
        return;
      }

      state.venda.notaFiscal.marcadores = preencherMarcadores();
      state.venda.notaFiscal.incidenciasCalculo = state.incidenciasCalculo;
      state.venda.notaFiscal.detalhesPagamento.forEach((d, indexD) => {
        state.venda.notaFiscal.detalhesPagamento[indexD].codigoNotaFiscal = state.venda.notaFiscal.codigo;
      });

      if (UtilitarioGeral.validaLista(state.venda.itens)) {
        const indexSemCodigos: number[] = [];
        for (let index = 0; index < state.venda.itens.length; index += 1) {
          if (!UtilitarioGeral.validaCodigo(state.venda.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
            indexSemCodigos.push(index);
          }
        }
        indexSemCodigos.forEach((index) => {
          state.venda.itens.splice(index, 1);
        });

        if (UtilitarioGeral.validaCodigo(state.venda.itens[0].itemNotaFiscal.impostos.codigoCfop)) {
          const cfop = await servicoCfop.obter(state.venda.itens[0].itemNotaFiscal.impostos.codigoCfop, state.venda.codigoEmpresa);
          if (cfop !== undefined) {
            state.venda.notaFiscal.naturezaOperacao = UtilitarioGeral.limitarQuantidadeMaximaCaracteres(cfop.descricao, 60);
          }
        }
      }

      if (state.venda.notaFiscal.totalImpostos.valorAproximadoTributosTotal > 0) {
        if (!state.venda.notaFiscal.dadosAdicionais.includes('VALOR APROX. TRIBUTOS R$')) {
          const percentual = UtilitarioMatematico.valorDecimal2Casas((state.venda.notaFiscal.totalImpostos.valorAproximadoTributosTotal / state.venda.notaFiscal.totalNotaFiscal) * 100);
          state.venda.notaFiscal.dadosAdicionais += `VALOR APROX. TRIBUTOS R$ ${UtilitarioMascara.mascararValor(state.venda.notaFiscal.totalImpostos.valorAproximadoTributosTotal)} (${percentual} %) Fonte: IBPT`;
        }
      }

      if (state.venda.codigo > 0 && state.venda.codigo !== undefined) {
        apresentarBarraProgresso('Aguarde por favor...');

        retorno = await servicoVendas.alterar(state.venda);
      } else {
        state.incluindoCabecalhoVenda = true;
        retorno = await servicoVendas.incluirCabecalho(state.venda);
      }

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        excluirCacheRegistro();
        if (state.venda.codigo === 0) {
          await obterVenda(retorno.codigoRegistro, false);
          state.incluindoCabecalhoVenda = false;
          ocultarBarraProgresso();
        } else {
          state.incluindoCabecalhoVenda = false;
          ocultarBarraProgresso();
          emit('confirmacao');
          apresentarMensagemSucesso(retorno.mensagem);
          modalBase.computedVisivel = false;
        }
      } else {
        state.incluindoCabecalhoVenda = false;
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        await preencherEmpresasDisponiveis();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        if (props.codigoVenda > 0 && props.codigoVenda !== undefined) {
          await obterVenda(props.codigoVenda);
        }

        telaBase.carregando = false;
        obterCacheRegistro();
        clearTimeout(debounceRenderizar);
        debounceRenderizar = setTimeout(async () => {
          focusElemento(1);
        }, 150);
      }
    });

    watch(async () => state.criacaoVendaAutomatico, async () => {
      if (state.criacaoVendaAutomatico) {
        await salvar();
      }
    });

    async function concluirEtapa() {
      Modal.confirm({
        title: `Deseja concluir a etapa da venda: ${state.venda.numero}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoVendas.concluirEtapa(state.venda.codigo);
          if (retorno.status !== 1) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemSucesso('Etapa concluída com sucesso!');
            emit('confirmacao');
            modalBase.computedVisivel = false;
          }
        },
      });
    }

    async function retornarEtapa() {
      Modal.confirm({
        title: `Deseja retornar a etapa anterior da venda: ${state.venda.numero}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoVendas.retornarEtapa(state.venda.codigo);
          if (retorno.status !== 1) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemSucesso('Venda retornada com sucesso!');
            emit('confirmacao');
            modalBase.computedVisivel = false;
          }
        },
      });
    }

    function excluir() {
      Modal.confirm({
        title: 'Realmente deseja excluir a venda?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoVendas.excluir(state.venda.codigo);
          if (retorno.status !== 1) {
            apresentarMensagemErro(retorno.mensagem);
          } else {
            apresentarMensagemSucesso('Venda excluída com sucesso!');
            emit('confirmacao');
            modalBase.computedVisivel = false;
          }
        },
      });
    }

    async function imprimir() {
      const retornoRelatorio = await servicoVendas.imprimirVenda(state.venda.codigo, ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    function obtemDescricaoIncidencia(identificador: string): string {
      if (identificador === 'OutrasDespesas') {
        return 'Outras Despesas';
      }

      return identificador;
    }

    async function atualizarAnexo(index: number) {
      if (state.venda.codigo === undefined || state.venda.codigo === 0) return;

      if (state.venda.notaFiscal.anexos[index].codigo === undefined || state.venda.notaFiscal.anexos[index].codigo === 0) {
        state.venda.notaFiscal.anexos[index].codigoNotaFiscal = state.venda.notaFiscal.codigo;
      }
    }

    async function excluirAnexo(codigoAnexo: number) {
      if (state.venda.codigo === undefined || state.venda.codigo === 0) return;

      await servicoNotaFiscal.excluirAnexo(codigoAnexo, state.venda.codigoEmpresa);
    }

    async function verificaFocoFormaPagamento() {
      if (state.venda.codigo === 0) {
        await salvar();
      }
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      requisicaoAutorizacaoBase,
      etapa,
      ETipoValorMovimentacao,
      ETipoMovimentoComercial,
      ETipoRelacaoPessoa,
      servicoVendas,
      UtilitarioGeral,
      UtilitarioMascara,
      salvarCacheRegistro,
      recuperarCacheRegistro,
      apresentarRetornoRequisicao,
      atualizarValoresTotais,
      obterFormaPagamento,
      buscarItensDadosAtualizados,
      obterVenda,
      salvar,
      concluirEtapa,
      retornarEtapa,
      excluir,
      imprimir,
      obtemDescricaoIncidencia,
      atualizarAnexo,
      excluirAnexo,
      focusElemento,
      proximoElemento,
      elementoAnterior,
      verificaFocoFormaPagamento,
      atualizarInformacoesPessoa,
      atualizarInformacoesColaborador,
      adicionarTaxasPagamento,
      removerTaxasPagamento,
    };
  },
});
