
import {
  Ref,
  computed, defineComponent, inject, onBeforeMount, reactive, watch,
} from 'vue';
import { useMagicKeys } from '@vueuse/core';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarProdutoMovimento from '@/components/Cadastros/Produtos/SelecionarProdutoMovimento.vue';
import SelecionarUnidadeProdutoMovimento from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidadeProdutoMovimento.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import VendaItemModal from '@/views/Vendas/Vendas/VendaItemModal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { EOrigemValorItem } from '@/models/Enumeradores/Compartilhados/EOrigemValorItem';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useFocusBase } from '@/core/composables/FocusBase';
import { IVendaItem } from '@/models/Entidades/Vendas/IVenda';
import {
  INotaFiscalIncidenciaCalculo, INotaFiscalItem, INotaFiscalItemImposto, INotaFiscalItemImpostoIcms, INotaFiscalItemImpostoIpi, INotaFiscalItemImpostoPis, INotaFiscalItemImpostoCofins, INotaFiscalItemImpostoSubstituicaoTributaria, INotaFiscalItemImpostoImportacao, INotaFiscalItemMedicamento, INotaFiscalItemCombustivel, INotaFiscalItemEstoque,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import SelecionarPrecoProdutoMovimento from '@/components/Cadastros/Precificacao/TabelasPreco/SelecionarPrecoProdutoMovimento.vue';
import { IDTOTabelaPrecoItemProduto } from '@/models/DTO/Cadastros/Precificacao/IDTOTabelaPrecoItemProduto';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import SelecionarEstoqueProdutoMovimento from '@/components/Cadastros/Estoques/SelecionarEstoqueProdutoMovimento.vue';
import DetalhesProdutoModal from '@/components/Cadastros/Produtos/DetalhesProdutoModal.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import { IParametrosConsultaDefinicaoMovimentacao } from '@/models/ParametrosRequisicao/Cadastros/Tributacoes/IParametrosConsultaDefinicaoMovimentacao';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import { IDTOPessoaMovimento } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaMovimento';
import { ICfop } from '@/models/Entidades/Cadastros/Tributacoes/ICfop';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';

export default defineComponent({
  name: 'VendaItens',
  props: {
    dadosPessoaMovimento: {
      type: Object as () => IDTOPessoaMovimento,
      required: true,
    },
    atualizarValores: {
      type: Boolean,
      required: true,
    },
    totalVenda: {
      type: Number,
      required: true,
    },
    incidencias: {
      type: Array as () => INotaFiscalIncidenciaCalculo[],
      required: true,
    },
    dadosAdicionais: {
      type: String,
      required: true,
    },
    itens: {
      type: Array as () => IVendaItem[],
      required: true,
    },
    tabelasPrecos: {
      type: Array as () => number[],
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    chaveItens: {
      type: Number,
      default: 0,
    },
    carregando: {
      type: Boolean,
      default: false,
    },
    adicionarPrimeiroItem: {
      type: Boolean,
      default: false,
    },
    buscarDadosFiscaisRecalcular: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icone,
    CampoNumerico,
    SelecionarProdutoMovimento,
    SelecionarUnidadeProdutoMovimento,
    SelecionarEstoqueProdutoMovimento,
    SelecionarPrecoProdutoMovimento,
    MensagemSemDados,
    VendaItemModal,
    DetalhesProdutoModal,
  },
  emits: ['update:itens', 'update:carregando', 'update:dadosAdicionais', 'update:buscarDadosFiscaisRecalcular', 'itemAlterado'],
  setup(props, { emit }) {
    let debounce = 0;
    let debounceQuantidade = 0;
    let debouncePreco = 0;
    let debounceRenderizarGrade = 0;
    const dadosEmpresa = inject('VENDAS_EMPRESA') as Ref<IDTODadosEmpresa>;
    const cfopSugerido = inject('VENDAS_CFOP_SUGERIDO') as Ref<ICfop>;
    const servicoVendas = new ServicoVendas();
    const servicoCenarioFiscal = new ServicoCenarioFiscal();
    servicoCenarioFiscal.requisicaoSistema();

    const keys = useMagicKeys();
    const shiftN = keys['Shift+N'];
    const { apresentarMensagemAlerta } = useTelaBase();
    const {
      focusBase, elementosFocaveis, obtemIndexElemento, focusElemento, proximoElemento, elementoAnterior, elementoAcima, elementoAbaixo,
    } = useFocusBase();
    focusBase.classElementos = 'ss-venda-itens';

    const state = reactive({
      colunasGrade: [] as IColumn[],
      codigoDefinicaoSelecionada: 0,
      indexItemSelecionado: -1,
      itemSelecionado: {} as IVendaItem,
      indexDetalhesPreco: -1,
      indexDetalhesEstoques: -1,
      existeProximo: false,
      existeAnterior: false,
      exibirDetalhesItem: false,
      exibirDetalhesProduto: false,
      telaMobile: false,
      apresentarEstoquesItem: false,
      inputFocusEstoqueItem: false,
      popOverHoverEstoqueItem: false,
      apresentarPrecosItem: false,
      inputFocusPrecosItem: false,
      popOverHoverPrecosItem: false,
    });

    const computedItens = computed({
      get: () => props.itens,
      set: (val: IVendaItem[]) => {
        emit('update:itens', val);
      },
    });

    const computedCarregando = computed({
      get: () => props.carregando,
      set: (val: boolean) => {
        emit('update:carregando', val);
      },
    });

    const computedDadosAdicionais = computed({
      get: () => props.dadosAdicionais,
      set: (val: string) => {
        emit('update:dadosAdicionais', val);
      },
    });

    const computedBuscarDadosFiscaisRecalcular = computed({
      get: () => props.buscarDadosFiscaisRecalcular,
      set: (val: boolean) => {
        emit('update:buscarDadosFiscaisRecalcular', val);
      },
    });

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Item', dataIndex: 'item', key: 'Item', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasGrade = [
          {
            title: 'Produto', dataIndex: 'produto', key: 'Produto', position: 1, visible: true, width: 290, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Unidade', dataIndex: 'unidade', key: 'Unidade', position: 3, visible: true, width: 35, ordering: false, align: 'center', ellipsis: true,
          },
          {
            title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 4, visible: true, ordering: false, align: 'right', width: 50, ellipsis: true,
          },
          {
            title: 'Valor Unitário', dataIndex: 'valorUnitario', key: 'ValorUnitario', position: 5, visible: true, ordering: false, align: 'right', width: 60, ellipsis: true,
          },
          {
            title: 'ICMS', dataIndex: 'icms', key: 'Icms', position: 6, visible: true, align: 'center', width: 30, ellipsis: true,
          },
          {
            title: 'IPI', dataIndex: 'ipi', key: 'Ipi', position: 7, visible: true, ordering: false, align: 'center', width: 30, ellipsis: true,
          },
          {
            title: 'Desconto', dataIndex: 'desconto', key: 'Desconto', position: 8, visible: true, ordering: false, align: 'right', width: 40, ellipsis: true,
          },
          {
            title: 'Valor Total', dataIndex: 'total', key: 'Total', position: 9, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 0, visible: true, width: 30, align: 'center', ellipsis: true,
          },
        ];
      }
    }

    function validarExisteProximoItem() {
      state.existeProximo = state.indexItemSelecionado < computedItens.value.length - 1;
    }

    function validarExisteItemAnterior() {
      state.existeAnterior = state.indexItemSelecionado > 0;
    }

    function instanciaNovoItem(): IVendaItem {
      const itemVenda = {} as IVendaItem;
      itemVenda.emDigitacao = false;
      itemVenda.dadosPrecoProduto = {} as IDTOTabelaPrecoItemProduto;
      itemVenda.dadosPrecoProduto.codigo = 0;
      itemVenda.dadosPrecoProduto.casasDecimaisTipoPreco = 2;
      itemVenda.percentualFormacaoPreco = 0;
      itemVenda.itemNotaFiscal = {} as INotaFiscalItem;
      itemVenda.itemNotaFiscal.estoquesDisponiveis = [] as IEstoque[];
      itemVenda.itemNotaFiscal.codigoProdutoDefinicao = 0;
      itemVenda.itemNotaFiscal.codigoUnidadePrincipal = 0;
      itemVenda.itemNotaFiscal.fatorUnidadePrincipal = 1;
      itemVenda.itemNotaFiscal.codigoUnidade = 0;
      itemVenda.itemNotaFiscal.fracionarUnidade = false;
      itemVenda.itemNotaFiscal.siglaUnidade = '';
      itemVenda.itemNotaFiscal.fatorConversao = 1;
      itemVenda.itemNotaFiscal.casasDecimaisUnidade = 2;
      itemVenda.codigoTabelaPrecoItemProduto = 0;
      itemVenda.itemNotaFiscal.precoUnitario = 0;
      itemVenda.itemNotaFiscal.casasDecimaisPrecoUnitario = 2;
      itemVenda.itemNotaFiscal.valorDesconto = 0;
      itemVenda.itemNotaFiscal.valorTotal = 0;
      itemVenda.itemNotaFiscal.movimentarEstoque = true;
      itemVenda.itemNotaFiscal.compoeTotal = true;
      itemVenda.itemNotaFiscal.origemFrete = EOrigemValorItem.Rateio;
      itemVenda.itemNotaFiscal.valorFrete = 0;
      itemVenda.itemNotaFiscal.origemOutrasDespesas = EOrigemValorItem.Rateio;
      itemVenda.itemNotaFiscal.origemSeguro = EOrigemValorItem.Rateio;
      itemVenda.itemNotaFiscal.valorSeguro = 0;
      itemVenda.itemNotaFiscal.impostos = {} as INotaFiscalItemImposto;
      itemVenda.itemNotaFiscal.impostos.codigoCfop = 0;
      itemVenda.itemNotaFiscal.impostos.icms = {} as INotaFiscalItemImpostoIcms;
      itemVenda.itemNotaFiscal.impostos.cst = '00';
      itemVenda.itemNotaFiscal.impostos.origemMercadoria = 0;
      itemVenda.itemNotaFiscal.impostos.icms.modalidadeBaseCalculoIcms = 3;
      itemVenda.itemNotaFiscal.impostos.icms.valorIcms = 0;
      itemVenda.itemNotaFiscal.impostos.ipi = {} as INotaFiscalItemImpostoIpi;
      itemVenda.itemNotaFiscal.impostos.ipi.cstIpi = '52';
      itemVenda.itemNotaFiscal.impostos.ipi.tipoCalculoIpi = 1;
      itemVenda.itemNotaFiscal.impostos.ipi.codigoEnquadramentoIpi = '999';
      itemVenda.itemNotaFiscal.impostos.ipi.valorIpi = 0;
      itemVenda.itemNotaFiscal.impostos.pis = {} as INotaFiscalItemImpostoPis;
      itemVenda.itemNotaFiscal.impostos.pis.cstPis = '07';
      itemVenda.itemNotaFiscal.impostos.pis.tipoCalculoPis = 1;
      itemVenda.itemNotaFiscal.impostos.pis.valorPis = 0;
      itemVenda.itemNotaFiscal.impostos.cofins = {} as INotaFiscalItemImpostoCofins;
      itemVenda.itemNotaFiscal.impostos.cofins.cstCofins = '07';
      itemVenda.itemNotaFiscal.impostos.cofins.tipoCalculoCofins = 1;
      itemVenda.itemNotaFiscal.impostos.cofins.valorCofins = 0;
      itemVenda.itemNotaFiscal.impostos.st = {} as INotaFiscalItemImpostoSubstituicaoTributaria;
      itemVenda.itemNotaFiscal.impostos.importacao = {} as INotaFiscalItemImpostoImportacao;
      itemVenda.itemNotaFiscal.impostos.importacao.valorImportacao = 0;
      itemVenda.itemNotaFiscal.impostos.importacao.valorIof = 0;
      itemVenda.itemNotaFiscal.impostos.importacao.despesasAduaneiras = 0;
      itemVenda.itemNotaFiscal.medicamento = {} as INotaFiscalItemMedicamento;
      itemVenda.itemNotaFiscal.combustivel = {} as INotaFiscalItemCombustivel;
      itemVenda.itemNotaFiscal.estoques = [] as INotaFiscalItemEstoque[];
      return itemVenda;
    }

    function adicionarItem() {
      state.indexItemSelecionado = -1;
      state.itemSelecionado = instanciaNovoItem();
      validarExisteProximoItem();
      validarExisteItemAnterior();
      state.exibirDetalhesItem = true;
    }

    function editarItem(index: number) {
      state.indexItemSelecionado = index;
      state.itemSelecionado = computedItens.value[index];
      validarExisteProximoItem();
      validarExisteItemAnterior();
      state.exibirDetalhesItem = true;
    }

    function exibirDetalhesProduto(index: number) {
      if (UtilitarioGeral.validaCodigo(computedItens.value[index].itemNotaFiscal.codigoProdutoDefinicao)) {
        state.codigoDefinicaoSelecionada = computedItens.value[index].itemNotaFiscal.codigoProdutoDefinicao;
        state.indexItemSelecionado = index;
        state.itemSelecionado = computedItens.value[index];
        state.exibirDetalhesProduto = true;
      } else {
        apresentarMensagemAlerta('É necessário um produto para utilizar este recurso.');
      }
    }

    function quantidadeFoco(index: number) {
      state.indexDetalhesEstoques = index;
      state.apresentarEstoquesItem = true;
    }

    function quantidadeBlur() {
      clearTimeout(debounceQuantidade);
      debounceQuantidade = setTimeout(() => {
        if (!state.popOverHoverEstoqueItem && !state.inputFocusEstoqueItem) {
          state.indexDetalhesEstoques = -1;
          state.apresentarEstoquesItem = false;
        }
      }, 600);
    }

    function precoFoco(index: number) {
      state.indexDetalhesPreco = index;
      state.apresentarPrecosItem = true;
    }

    function precoBlur() {
      clearTimeout(debouncePreco);
      debouncePreco = setTimeout(() => {
        state.indexDetalhesPreco = -1;
        state.apresentarPrecosItem = false;
      }, 300);
    }

    function proximoItem() {
      editarItem(state.indexItemSelecionado + 1);
    }

    function itemAnterior() {
      editarItem(state.indexItemSelecionado - 1);
    }

    function salvarItemGrade(index: number, item: IVendaItem) {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        computedItens.value[index] = item;
        computedItens.value[index].alterado = true;
        if (props.editavel) {
          computedItens.value[index] = servicoVendas.calcularTotalItem(computedItens.value[index]);
        }
        emit('itemAlterado', index);
      }, 600);
    }

    function salvarItem(item: IVendaItem) {
      if (state.indexItemSelecionado > -1) {
        computedItens.value[state.indexItemSelecionado] = item;
      } else {
        computedItens.value.push(item);
        state.indexItemSelecionado = computedItens.value.length - 1;
      }
      state.exibirDetalhesItem = false;
      emit('itemAlterado', state.indexItemSelecionado);
    }

    function adicionarItemGrade() {
      if (UtilitarioGeral.objetoValido(props.itens)) {
        const itens = props.itens;
        if (itens.length === 0 || UtilitarioGeral.validaCodigo(props.itens[props.itens.length - 1].itemNotaFiscal.codigoProdutoDefinicao)) {
          computedItens.value.push(instanciaNovoItem());
        }
      }
      clearTimeout(debounceRenderizarGrade);
      debounceRenderizarGrade = setTimeout(async () => {
        focusElemento(elementosFocaveis.value.length - 5);
      }, 300);
    }

    async function excluirItem(index: number, evento?: any) {
      if (!UtilitarioGeral.validaCodigo(props.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
        computedItens.value.splice(index, 1);
        return;
      }
      Modal.confirm({
        title: 'Você confirma a exclusão do item?',
        content: `${props.itens[index].itemNotaFiscal.descricao}`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: 'ok',
        onOk: async () => {
          let indexCampo = -1;
          const ultimoItem = (index === computedItens.value.length - 1);
          if (evento !== undefined) {
            indexCampo = obtemIndexElemento(evento);
          }
          const codigoItem = computedItens.value[index].codigo;
          computedItens.value.splice(index, 1);
          emit('itemAlterado', codigoItem);
          if (UtilitarioGeral.validaLista(computedItens.value)) {
            if (ultimoItem) {
              focusElemento(elementosFocaveis.value.length - 7);
            } else if (indexCampo !== undefined && indexCampo >= 0) {
              focusElemento(indexCampo);
            }
          }
        },
      });
    }

    function alterarUnidade(index: number) {
      if (props.editavel) {
        computedItens.value[index] = servicoVendas.calcularTotalItem(computedItens.value[index]);
        emit('itemAlterado', index);
      }
    }

    function defineValorUnitarioCalculaTotal(index: number, precoUnitario: number) {
      if (props.editavel) {
        computedItens.value[index].itemNotaFiscal.precoUnitario = precoUnitario;
        computedItens.value[index] = servicoVendas.calcularTotalItem(computedItens.value[index]);
        emit('itemAlterado', index);
      }
    }

    function defineFocoPrecos(index: number) {
      state.indexDetalhesEstoques = -1;
      state.indexDetalhesPreco = index;
    }

    async function obterDadosFiscaisProduto(index: number) {
      if (UtilitarioGeral.validaCodigo(computedItens.value[index].itemNotaFiscal.codigoProdutoDefinicao)) {
        computedCarregando.value = true;

        if (!UtilitarioGeral.valorValido(computedDadosAdicionais.value)) {
          computedDadosAdicionais.value = '';
        }

        computedItens.value[index].itemNotaFiscal.codigoUnidade = 0;
        computedItens.value[index].itemNotaFiscal.fracionarUnidade = false;
        computedItens.value[index].itemNotaFiscal.siglaUnidade = '';
        computedItens.value[index].itemNotaFiscal.fatorConversao = 1;
        computedItens.value[index].itemNotaFiscal.casasDecimaisUnidade = 2;

        computedItens.value[index].dadosPrecoProduto = {} as IDTOTabelaPrecoItemProduto;
        computedItens.value[index].dadosPrecoProduto.codigo = 0;
        computedItens.value[index].dadosPrecoProduto.casasDecimaisTipoPreco = 2;
        computedItens.value[index].percentualFormacaoPreco = 0;
        computedItens.value[index].itemNotaFiscal.estoques = [] as INotaFiscalItemEstoque[];
        computedItens.value[index].codigoTabelaPrecoItemProduto = 0;

        const parametrosConsultaDefinicaoMovimentacao: IParametrosConsultaDefinicaoMovimentacao = {} as IParametrosConsultaDefinicaoMovimentacao;
        parametrosConsultaDefinicaoMovimentacao.tipoMovimentoComercial = ETipoMovimentoComercial.Vendas;
        parametrosConsultaDefinicaoMovimentacao.codigoEmpresa = dadosEmpresa.value.codigoEmpresa;
        parametrosConsultaDefinicaoMovimentacao.codigoEstado = props.dadosPessoaMovimento.codigoEstado;
        parametrosConsultaDefinicaoMovimentacao.tipoAtividade = props.dadosPessoaMovimento.tipoAtividade;
        parametrosConsultaDefinicaoMovimentacao.tipoIe = props.dadosPessoaMovimento.tipoIe;
        parametrosConsultaDefinicaoMovimentacao.pessoaSuperSimples = props.dadosPessoaMovimento.simplesNacional;
        parametrosConsultaDefinicaoMovimentacao.codigoProdutoDefinicao = computedItens.value[index].itemNotaFiscal.codigoProdutoDefinicao;
        parametrosConsultaDefinicaoMovimentacao.codigoNcm = computedItens.value[index].itemNotaFiscal.impostos.codigoNcm;
        parametrosConsultaDefinicaoMovimentacao.origemMercadoria = computedItens.value[index].itemNotaFiscal.impostos.origemMercadoria;

        const definicoesTributarias = await servicoCenarioFiscal.obterDefinicoesMovimentacao(parametrosConsultaDefinicaoMovimentacao);
        if (definicoesTributarias.sucesso) {
          if (definicoesTributarias.encontrouDefinicoesIcms) {
            computedItens.value[index].itemNotaFiscal.impostos.codigoCfop = definicoesTributarias.icms.codigoCfop;
            computedItens.value[index].itemNotaFiscal.impostos.cfop = definicoesTributarias.icms.cfop;
            if (UtilitarioGeral.valorValido(definicoesTributarias.icms.cstIcms)) {
              computedItens.value[index].itemNotaFiscal.impostos.cst = definicoesTributarias.icms.cstIcms;
              computedItens.value[index].itemNotaFiscal.impostos.cson = '';
            } else {
              computedItens.value[index].itemNotaFiscal.impostos.cson = definicoesTributarias.icms.cson;
              computedItens.value[index].itemNotaFiscal.impostos.cst = '';
            }
            computedItens.value[index].itemNotaFiscal.impostos.icms.modalidadeBaseCalculoIcms = definicoesTributarias.icms.modalidadeBaseCalculoIcms;
            computedItens.value[index].itemNotaFiscal.impostos.icms.aliquotaIcms = definicoesTributarias.icms.aliquotaIcms;
            computedItens.value[index].itemNotaFiscal.impostos.icms.reducaoBaseCalculoIcms = definicoesTributarias.icms.reducaoBaseCalculoIcms;
            computedItens.value[index].itemNotaFiscal.impostos.icms.aliquotaFcp = definicoesTributarias.icms.aliquotaFcp;
            computedItens.value[index].itemNotaFiscal.impostos.icms.calcularIcmsInterestadual = definicoesTributarias.icms.calcularIcmsInterestadual;

            if (UtilitarioGeral.valorValido(definicoesTributarias.icms.informacaoComplementar)) {
              if (UtilitarioGeral.valorValido(computedDadosAdicionais.value)) {
                if (!computedDadosAdicionais.value.includes(definicoesTributarias.icms.informacaoComplementar)) {
                  computedDadosAdicionais.value += definicoesTributarias.icms.informacaoComplementar;
                }
              } else {
                computedDadosAdicionais.value += definicoesTributarias.icms.informacaoComplementar;
              }
            }
          }

          if (definicoesTributarias.encontrouDefinicoesSubstituicaoTributaria) {
            computedItens.value[index].itemNotaFiscal.impostos.st.modalidadeBaseCalculoSubstituicao = definicoesTributarias.icms.substituicao.modalidadeBaseCalculoIcmsSubstituicao;
            computedItens.value[index].itemNotaFiscal.impostos.st.aliquotaSubstituicaoTributaria = definicoesTributarias.icms.substituicao.aliquotaIcmsSubstituicao;
            computedItens.value[index].itemNotaFiscal.impostos.st.aliquotaIcmsInterno = definicoesTributarias.icms.substituicao.aliquotaIcmsInterno;
            computedItens.value[index].itemNotaFiscal.impostos.st.margemValorAdicionado = definicoesTributarias.icms.substituicao.margemValorAdicionado;
            computedItens.value[index].itemNotaFiscal.impostos.st.pautaIcmsSubstituicao = definicoesTributarias.icms.substituicao.pautaIcmsSubstituicao;
            computedItens.value[index].itemNotaFiscal.impostos.st.reducaoBaseCalculoSubstituicao = definicoesTributarias.icms.substituicao.reducaoBaseCalculoIcmsSubstituicao;
          }

          if (definicoesTributarias.encontrouDefinicoesIpi) {
            computedItens.value[index].itemNotaFiscal.impostos.ipi.cstIpi = definicoesTributarias.ipi.cstIpi;
            computedItens.value[index].itemNotaFiscal.impostos.ipi.tipoCalculoIpi = definicoesTributarias.ipi.tipoCalculoIpi;
            computedItens.value[index].itemNotaFiscal.impostos.ipi.aliquotaIpi = definicoesTributarias.ipi.aliquotaIpi;
            computedItens.value[index].itemNotaFiscal.impostos.ipi.codigoEnquadramentoIpi = definicoesTributarias.ipi.codigoEnquadramentoIpi;
            if (UtilitarioGeral.valorValido(definicoesTributarias.ipi.informacaoComplementar)) {
              if (UtilitarioGeral.valorValido(computedDadosAdicionais.value)) {
                if (!computedDadosAdicionais.value.includes(definicoesTributarias.ipi.informacaoComplementar)) {
                  computedDadosAdicionais.value += definicoesTributarias.ipi.informacaoComplementar;
                }
              } else {
                computedDadosAdicionais.value += definicoesTributarias.ipi.informacaoComplementar;
              }
            }
          }

          if (definicoesTributarias.encontrouDefinicoesPis) {
            computedItens.value[index].itemNotaFiscal.impostos.pis.cstPis = definicoesTributarias.pis.cstPis;
            computedItens.value[index].itemNotaFiscal.impostos.pis.tipoCalculoPis = definicoesTributarias.pis.tipoCalculoPis;
            computedItens.value[index].itemNotaFiscal.impostos.pis.aliquotaPis = definicoesTributarias.pis.aliquotaPis;
            computedItens.value[index].itemNotaFiscal.impostos.pis.reducaoBaseCalculoPis = definicoesTributarias.pis.reducaoBaseCalculoPis;

            if (UtilitarioGeral.valorValido(definicoesTributarias.pis.informacaoComplementar)) {
              if (UtilitarioGeral.valorValido(computedDadosAdicionais.value)) {
                if (!computedDadosAdicionais.value.includes(definicoesTributarias.pis.informacaoComplementar)) {
                  computedDadosAdicionais.value += definicoesTributarias.pis.informacaoComplementar;
                }
              } else {
                computedDadosAdicionais.value += definicoesTributarias.pis.informacaoComplementar;
              }
            }
          }

          if (definicoesTributarias.encontrouDefinicoesCofins) {
            computedItens.value[index].itemNotaFiscal.impostos.cofins.cstCofins = definicoesTributarias.cofins.cstCofins;
            computedItens.value[index].itemNotaFiscal.impostos.cofins.tipoCalculoCofins = definicoesTributarias.cofins.tipoCalculoCofins;
            computedItens.value[index].itemNotaFiscal.impostos.cofins.aliquotaCofins = definicoesTributarias.cofins.aliquotaCofins;
            computedItens.value[index].itemNotaFiscal.impostos.cofins.reducaoBaseCalculoCofins = definicoesTributarias.cofins.reducaoBaseCalculoCofins;

            if (UtilitarioGeral.valorValido(definicoesTributarias.cofins.informacaoComplementar)) {
              if (UtilitarioGeral.valorValido(computedDadosAdicionais.value)) {
                if (!computedDadosAdicionais.value.includes(definicoesTributarias.cofins.informacaoComplementar)) {
                  computedDadosAdicionais.value += definicoesTributarias.cofins.informacaoComplementar;
                }
              } else {
                computedDadosAdicionais.value += definicoesTributarias.cofins.informacaoComplementar;
              }
            }
          }
        }

        if (!UtilitarioGeral.validaCodigo(computedItens.value[index].itemNotaFiscal.impostos.codigoCfop) && UtilitarioGeral.objetoValido(cfopSugerido.value)) {
          computedItens.value[index].itemNotaFiscal.impostos.codigoCfop = cfopSugerido.value.codigo;
          computedItens.value[index].itemNotaFiscal.impostos.cfop = cfopSugerido.value.codigoCfop;
        }

        computedCarregando.value = false;
      }
    }

    watch(shiftN, () => { adicionarItemGrade(); });

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      preencherColunas();
    });

    function verificaElementoEsquerda(emDigitacao: boolean, evento: any) {
      if (!emDigitacao) {
        elementoAnterior(evento);
      }
    }

    function verificaElementoDireita(emDigitacao: boolean, evento: any) {
      if (!emDigitacao) {
        proximoElemento(evento);
      }
    }
    function verificaElementoAcima(emDigitacao: boolean, evento: any, dataAttributeColuna: string) {
      if (!emDigitacao) {
        elementoAcima(evento, dataAttributeColuna);
      }
    }

    function verificaElementoAbaixo(emDigitacao: boolean, evento: any, dataAttributeColuna: string) {
      if (!emDigitacao) {
        elementoAbaixo(evento, dataAttributeColuna);
      }
    }

    function verificaElementoProximoElementoDesconto(emDigitacao: boolean, index: number, evento: any) {
      if (index === computedItens.value.length - 1) {
        adicionarItemGrade();
        clearTimeout(debounceRenderizarGrade);
        debounceRenderizarGrade = setTimeout(async () => {
          proximoElemento(evento);
        }, 150);
      } else {
        proximoElemento(evento);
      }
    }
    function focoPrimeiroProduto() {
      clearTimeout(debounceRenderizarGrade);
      debounceRenderizarGrade = setTimeout(async () => {
        focusElemento(0);
      }, 300);
    }

    watch(async () => props.adicionarPrimeiroItem, async () => {
      if (props.adicionarPrimeiroItem) {
        clearTimeout(debounceRenderizarGrade);
        debounceRenderizarGrade = setTimeout(async () => {
          adicionarItemGrade();
          focoPrimeiroProduto();
        }, 1500);
      }
    }, { immediate: true });

    watch(async () => props.buscarDadosFiscaisRecalcular, async () => {
      if (props.buscarDadosFiscaisRecalcular) {
        for (let index = 0; index < computedItens.value.length; index += 1) {
          if (UtilitarioGeral.validaCodigo(computedItens.value[index].itemNotaFiscal.codigoProdutoDefinicao)) {
            obterDadosFiscaisProduto(index);
          }
        }
        computedBuscarDadosFiscaisRecalcular.value = false;
        emit('itemAlterado', 0);
      }
    }, { immediate: true });

    return {
      props,
      state,
      computedItens,
      dadosEmpresa,
      UtilitarioMascara,
      UtilitarioMatematico,
      proximoItem,
      itemAnterior,
      adicionarItem,
      computedCarregando,
      adicionarItemGrade,
      editarItem,
      excluirItem,
      salvarItem,
      salvarItemGrade,
      focusElemento,
      proximoElemento,
      elementoAnterior,
      verificaElementoAbaixo,
      verificaElementoAcima,
      verificaElementoEsquerda,
      verificaElementoDireita,
      elementoAcima,
      elementoAbaixo,
      alterarUnidade,
      defineValorUnitarioCalculaTotal,
      obterDadosFiscaisProduto,
      defineFocoPrecos,
      verificaElementoProximoElementoDesconto,
      exibirDetalhesProduto,
      quantidadeFoco,
      quantidadeBlur,
      precoFoco,
      precoBlur,
    };
  },
});
