
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import { IDadosRelatorioVendaProduto, IDadosRelatorioVendaProdutoItem } from '@/models/Relatorios/Venda/IDadosRelatorioVendaProduto';
import { IFiltrosRelatorioVendas } from '@/models/Relatorios/Venda/IFiltrosRelatorioVendas';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import ServicoRelatoriosVendas from '@/servicos/Relatorios/ServicoRelatoriosVendas';
import storeSistema from '@/store/storeSistema';
import { ETipoRelatorioVenda } from '@/models/Enumeradores/Relatorios/ETipoRelatorioVenda';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarTipoRelatorioVendas from '@/components/Relatorios/Vendas/SelecionarTipoRelatorioVendas.vue';

export default defineComponent({
  name: 'RelatorioVendaProduto',
  components: {
    TituloPadrao,
    Icone,
    Card,
    SelecionarTipoRelatorioVendas,
    SelecionarDataInicialFinal,
    SelecionarProduto,
    PreviewPdf,
  },
  setup(props) {
    const servicoSistema = new ServicoSistema();
    const servicoRelatorioVenda = new ServicoRelatoriosVendas();
    const {
      telaBase, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemAlerta, apresentarMensagemErro,
      preencherEmpresasDisponiveis, verificaConceitoParaApresentarEmpresas, defineEmpresasSelecionadasComPermissao,
      preencherDadosRota,
    } = useTelaBase();
    const { gradeBase } = useGradeBase();
    const state = reactive({
      dataInicial: '01/01/2001',
      dataFinal: '10/01/2001',
      filtros: {} as IFiltrosRelatorioVendas,
      filtrosUtilizados: {} as IFiltrosRelatorioVendas,
      resultado: {} as IDadosRelatorioVendaProduto,
      itens: [] as IDadosRelatorioVendaProdutoItem[],
      paginacao: {} as IPagination,
      paginacaoAPI: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      totalRegistrosComMascara: '100',
      arquivoPdf: [] as IArquivoPdf[],
      mostrarFiltrosInteligentes: 1,
      mostrarFiltros: 0,
      mostrarItens: false,
      cacheAutomatico: {} as ISalvamentoAutomatico,
    });
    telaBase.apresentarEmpresas = false;

    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'CodigoProduto', key: 'Item', position: 1, visible: false,
          },
        ];
      } else if (state.filtros.tipo === ETipoRelatorioVenda.Simplificado) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'codigo', position: 1, visible: true, align: 'right', width: 100,
          },
          {
            title: 'Descrição', dataIndex: 'descricao', key: 'descricao', position: 2, visible: true, align: 'left', width: 300,
          },
          {
            title: 'UN', dataIndex: 'unidade', key: 'unidade', position: 3, visible: true, align: 'left', width: 65,
          },
          {
            title: 'Qtd', dataIndex: 'quantidade', key: 'quantidade', position: 4, visible: true, align: 'center',
          },
          {
            title: 'Valor Unitário', dataIndex: 'valorUnitario', key: 'valorUnitario', position: 5, visible: true, align: 'left', customRenderRow: ECustomRenderRow.MascararFracionado,
          },
          {
            title: 'Valor Desconto', dataIndex: 'valorDesconto', key: 'valorDesconto', position: 6, visible: true, align: 'left', customRenderRow: ECustomRenderRow.MascararFracionado,
          },
          {
            title: 'Valor Total', dataIndex: 'valorTotal', key: 'valorTotal', position: 7, visible: true, align: 'left', customRenderRow: ECustomRenderRow.MascararFracionado,
          },
          {
            title: 'Custo', dataIndex: 'valorCusto', key: 'valorCusto', position: 8, visible: true, align: 'left', customRenderRow: ECustomRenderRow.MascararFracionado,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Data Venda', dataIndex: 'dataVendaFormatada', key: 'dataVenda', position: 1, visible: true, align: 'left',
          },
          {
            title: 'Numero Venda', dataIndex: 'numeroVenda', key: 'numeroVenda', position: 2, visible: true, align: 'left',
          },
          {
            title: 'Código', dataIndex: 'codigo', key: 'codigo', position: 3, visible: true, align: 'right', width: 100,
          },
          {
            title: 'Descrição', dataIndex: 'descricao', key: 'descricao', position: 4, visible: true, align: 'left', width: 300,
          },
          {
            title: 'UN', dataIndex: 'unidade', key: 'unidade', position: 5, visible: true, align: 'left', width: 65,
          },
          {
            title: 'Qtd', dataIndex: 'quantidade', key: 'quantidade', position: 6, visible: true, align: 'center',
          },
          {
            title: 'Valor Unitário', dataIndex: 'valorUnitario', key: 'valorUnitario', position: 7, visible: true, align: 'left', customRenderRow: ECustomRenderRow.MascararFracionado,
          },
          {
            title: 'Valor Desconto', dataIndex: 'valorDesconto', key: 'valorDesconto', position: 8, visible: true, align: 'left', customRenderRow: ECustomRenderRow.MascararFracionado,
          },
          {
            title: 'Valor Total', dataIndex: 'valorTotal', key: 'valorDesconto', position: 9, visible: true, align: 'left', customRenderRow: ECustomRenderRow.MascararFracionado,
          },
        ];
      }
    }

    function obterIdentificadorCache(): string {
      return `RELATORIO_VENDA_PRODUTO_${storeSistema.getters.codigoUsuarioAutenticado()}`;
    }

    function salvarCache() {
      const identificador = obterIdentificadorCache();
      state.cacheAutomatico.data = UtilitarioData.obterDataAtual();
      state.cacheAutomatico.codigoEmpresa = state.filtros.empresa;
      state.cacheAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.cacheAutomatico.identificador = telaBase.identificadorRecurso;
      state.cacheAutomatico.dados = JSON.stringify(state.filtros);
      servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}`, state.cacheAutomatico);
    }

    async function buscarRelatorio() {
      telaBase.carregando = true;
      state.filtros.dataInicial = state.dataInicial;
      state.filtros.dataFinal = state.dataFinal;
      state.filtros.numeroPagina = state.paginacao.current;
      state.filtros.qtdRegistrosPagina = state.paginacao.pageSize;
      state.filtros.qtdTotalRegistros = state.paginacao.total;
      state.resultado = await servicoRelatorioVenda.obterVendaProduto(state.filtros);
      state.filtrosUtilizados = UtilitarioGeral.clonarObjeto(state.filtros);
      salvarCache();
      preencherColunas();
      state.mostrarItens = true;
      state.itens = state.resultado.itens.dados;
      state.paginacaoAPI = state.resultado.itens.metaData;
      state.mostrarFiltros = 0;
      state.mostrarFiltrosInteligentes = 0;
      telaBase.carregando = false;
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(state.timerRegistros);
      let totalRegistros = 100;
      state.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        state.paginacao.total = totalRegistros;
        state.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        await buscarRelatorio();
      }, 600);
    }

    async function imprimir(tipoArquivoRelatorio:ETipoArquivo) {
      telaBase.carregando = true;
      if (!UtilitarioGeral.validaLista(state.itens)) {
        await buscarRelatorio();
      }
      const retornoRelatorio = await servicoRelatorioVenda.obterArquivoVendaProduto(tipoArquivoRelatorio, state.filtrosUtilizados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivoPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.filtros.empresa);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      await buscarRelatorio();
    }

    function usarFiltroInteligente(filtro: number) {
      switch (filtro) {
        case 2:
          state.filtros.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.filtros.dataInicial = UtilitarioData.adicionarMesNaData(state.dataInicial, 3);
          state.filtros.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.dataFinal = UtilitarioData.adicionarMesNaData(state.dataFinal, 1);
          break;
        case 3:
          state.filtros.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.filtros.dataInicial = UtilitarioData.adicionarMesNaData(state.dataInicial, 1);
          state.filtros.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.dataFinal = UtilitarioData.adicionarMesNaData(state.dataFinal, 1);
          state.filtros.tipo = ETipoRelatorioVenda.Agrupado;
          break;
        case 4:
          state.filtros.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.filtros.dataInicial = UtilitarioData.adicionarMesNaData(state.dataInicial, 3);
          state.filtros.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.dataFinal = UtilitarioData.adicionarMesNaData(state.dataFinal, 1);
          state.filtros.tipo = ETipoRelatorioVenda.Agrupado;
          break;
        default:
          state.filtros.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.filtros.dataInicial = UtilitarioData.adicionarMesNaData(state.dataInicial, 1);
          state.filtros.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.dataFinal = UtilitarioData.adicionarMesNaData(state.dataFinal, 1);
          break;
      }
      buscarRelatorio();
    }

    function limparFiltros() {
      state.filtros = {} as IFiltrosRelatorioVendas;
      state.filtros.empresa = storeSistema.getters.codigoEmpresaOperacao();
      state.filtros.tipo = ETipoRelatorioVenda.Simplificado;
      state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
      state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
    }

    async function onPageChange(current: number, pageSize: number) {
      state.paginacao.current = current;
      state.paginacao.pageSize = pageSize;
      await buscarRelatorio();
    }

    async function onShowSizeChange(current: number, pageSize: number) {
      state.paginacao.current = 1;
      state.paginacao.pageSize = pageSize;
      await buscarRelatorio();
    }

    watch(() => storeSistema.state.layoutMobile, () => {
      preencherColunas();
    });

    onBeforeMount(async () => {
      preencherDadosRota();
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      verificaConceitoParaApresentarEmpresas();
      // recuperarCache();
      preencherColunas();
      limparFiltros();
      state.paginacao.total = 100;
      state.paginacao.showSizeChanger = true;
      state.paginacao.pageSize = 100;
      state.paginacao.current = 1;
      state.paginacao.pageSizeOptions = ['10', '50', '100', '500', '1000'];
    });

    return {
      state,
      props,
      window,
      ETipoArquivo,
      ECustomRenderRow,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioDispositivo,
      storeSistema,
      telaBase,
      gradeBase,
      limparFiltros,
      obterIdentificadorCache,
      salvarCache,
      buscarRelatorio,
      atualizarSelecaoEmpresas,
      usarFiltroInteligente,
      imprimir,
      alterarQuantidadeRegistroTotal,
      onPageChange,
      onShowSizeChange,
    };
  },
});
